
import React, { useState } from "react";
import { stateToHTML } from "draft-js-export-html"
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { convertToHTML } from 'draft-convert';


export default function HTMLTextEditor(props) {
    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    const handleEditorChange = (state) => {
        setEditorState(state);
        convertContentToHTML();
    }

    const convertContentToHTML = () => {
        let currentContentAsHTML = stateToHTML(editorState.getCurrentContent());
        console.log(currentContentAsHTML)
        props.onUpdate(currentContentAsHTML)
    }

    return (
        <div>
            <header className="App-header">
                Enter Terms & Condition
            </header>
            <Editor
                editorState={editorState}
                onEditorStateChange={handleEditorChange}
                wrapperClassName="wrapper-class"
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"
            />
        </div >
    );
};
