import { CheckOutlined, CloseCircleFilled, CloseOutlined, EditOutlined, MinusCircleOutlined, PlusOutlined, UsergroupAddOutlined } from "@ant-design/icons";
import LoadingSpinner from "Components/LoadingSpinner";
import SearchAndSelectDevice from "Components/Search/SearchAndSelectDevice";
import SearchAndSelectGroup from "Components/Search/SearchAndSelectGroup";
import SearchAndSelectPolicy from "Components/Search/SearchAndSelectPolicy";
import SearchAndSelectUser from "Components/Search/SearchAndSelectUser";
import { StatusCodes } from "http-status-codes";
import { find, first } from "lodash";
import { useState } from "react";
import { useEffect } from "react";
import { createAppDistributions, deleteAppDistribution, getAppDistributionDetails, getAppDistributions } from "utils/apis";
import { Popover, Typography } from 'antd';
const { Text } = Typography;
const { Descriptions, List, Empty, Popconfirm, Button, Divider, Space, Select, message, Card, Checkbox, Form, Input, Modal, } = require("antd");

export const DISTRO_TYPE = {
    POLICY: "policy",
    GROUP: "group",
    USER: "user",
    DEVICE: "device"
}

export default function AppDistributionDetails({ appDetails }) {

    const [distributionInfo, setDistributionInfo] = useState(null)

    async function fetchAppDistros() {
        const distros = await getAppDistributions(appDetails.id)
        setDistributionInfo(distros.data)
    }

    useEffect(async () => {
        fetchAppDistros()
    }, [])

    const onDeleteDistroCallback = () => { fetchAppDistros() }

    if (distributionInfo == null) {
        return <div>
            <LoadingSpinner loadingText={"Loading distributions..."} />
        </div>
    } else {
        return <div>
            <Space direction="vertical">
                <NewDistributionComponent appDetails={appDetails} onNewDistroCallback={() => fetchAppDistros()} />
                <Divider dashed orientation="left" orientationMargin={0}>Current Distributions</Divider>
                <Descriptions column={4}>
                    <Descriptions.Item label="Policies">
                        <DistributionList appId={appDetails.id} distroType={DISTRO_TYPE.POLICY} distributions={distributionInfo.find((e) => e.type == DISTRO_TYPE.POLICY).data} onDeleteDistroCallback={onDeleteDistroCallback} />
                    </Descriptions.Item>

                    <Descriptions.Item label="Groups">
                        <DistributionList appId={appDetails.id} distroType={DISTRO_TYPE.GROUP} distributions={distributionInfo.find((e) => e.type == DISTRO_TYPE.GROUP).data} onDeleteDistroCallback={onDeleteDistroCallback} />
                    </Descriptions.Item>

                    <Descriptions.Item label="Users">
                        <DistributionList appId={appDetails.id} distroType={DISTRO_TYPE.USER} distributions={distributionInfo.find((e) => e.type == DISTRO_TYPE.USER).data} onDeleteDistroCallback={onDeleteDistroCallback} />
                    </Descriptions.Item>

                    <Descriptions.Item label="Devices">
                        <DistributionList appId={appDetails.id} distroType={DISTRO_TYPE.DEVICE} distributions={distributionInfo.find((e) => e.type == DISTRO_TYPE.DEVICE).data} onDeleteDistroCallback={onDeleteDistroCallback} />
                    </Descriptions.Item>
                </Descriptions>
            </Space>
        </div>
    }
}

function DistroOptionsSearchComponent({ distroType, setDistroTargetId }) {
    return <>
        {(distroType == DISTRO_TYPE.POLICY) && <SearchAndSelectPolicy setDistroTargetId={setDistroTargetId} />}
        {(distroType == DISTRO_TYPE.GROUP) && <SearchAndSelectGroup setDistroTargetId={setDistroTargetId} />}
        {(distroType == DISTRO_TYPE.USER) && <SearchAndSelectUser setDistroTargetId={setDistroTargetId} />}
        {(distroType == DISTRO_TYPE.DEVICE) && <SearchAndSelectDevice setDistroTargetId={setDistroTargetId} />}
    </>
}

function NewDistributionConfigComponent({ appTracks, managedConfigAttrs, updateDistroConfig }) {
    const INSTALL_TYPE = {
        AVAILABLE: "Available to install",
        FORCE_INSTALLED: "Install forcefully (can't be uninstalled)",
        PREINSTALLED: "Auto Install one time",
        BLOCKED: "Block",
    }
    const [form] = Form.useForm();

    const appTrackOptions = [
        {
            "trackId": "production",
            "trackAlias": "production"
        }
    ]
    if (appTracks != null || appTracks != undefined) {
        appTrackOptions.push(...appTracks)
    }

    const [installType, setInstallType] = useState(first(Object.keys(INSTALL_TYPE)));
    const [managedConfiguration, setManagedConfiguration] = useState({});
    const [disabled, setDisabled] = useState(false);
    const [accessibleTrackIds, setAccessibleTrackIds] = useState([first(appTrackOptions).trackId]);

    useEffect(() => {
        updateDistroConfig({
            installType,
            managedConfiguration,
            disabled,
            accessibleTrackIds
        })
    }, [installType, managedConfiguration, disabled, accessibleTrackIds])

    return <>
        <Form form={form} >
            <Form.Item
                label="Install type"
                rules={[{ required: true, },]}
            >
                <Select
                    style={{ width: 300 }}
                    allowClear={false}
                    labelInValue
                    defaultValue={INSTALL_TYPE.AVAILABLE}
                    onChange={(e) => {
                        setInstallType(e.value)
                    }}
                    options={Object.entries(INSTALL_TYPE).map((e) => {
                        // [key, value]
                        return { value: e[0], label: e[1] }
                    }
                    )}>

                </Select>
            </Form.Item>
            <Form.Item
                label="Disabled"
                rules={[{ required: true, },]}
            >
                <Checkbox onChange={(e) => { setDisabled(e.target.checked) }} />
            </Form.Item>
            <Form.Item
                label="App tracks"
                rules={[{ required: true, },]}
            >
                <Select
                    labelInValue
                    defaultValue={find(appTrackOptions, ((e) => { return e.trackId == first(accessibleTrackIds) })).trackId}
                    placeholder={"App track"}
                    style={{ width: 200 }}
                    onChange={(e) => { setAccessibleTrackIds([e.value]) }}
                    options={appTrackOptions.map((e) => {
                        return { label: e.trackAlias, value: e.trackId }
                    })}
                />
            </Form.Item>
            <Form.Item
                label="Managed Configuration"
                rules={[{ required: false, },]}
            >
                <ManagedConfigurationComponent
                    managedConfigAttrs={managedConfigAttrs}
                    managedConfiguration={managedConfiguration}
                    setManagedConfiguration={(config) => {
                        setManagedConfiguration(config)
                    }}
                />
            </Form.Item>
        </Form>
    </>
}

function ManagedConfigurationComponent({ setManagedConfiguration, managedConfigAttrs, managedConfiguration }) {
    if (managedConfiguration == null || managedConfiguration == undefined) {
        managedConfiguration = {}
    }
    const [showSetConfigModal, setShowSetConfigModal] = useState(false);
    const [showConfigTemplateModal, setShowConfigTemplateModal] = useState(false);

    const config = []
    for (const [key, value] of Object.entries(managedConfiguration)) {
        if (config.length == 0 || (config.find((e) => e.key == key) == undefined)) {
            config.push({ 'key': key, 'value': value })
        }
    }

    return <>
        <Button
            type="primary"
            shape="round"
            onClick={() => { setShowSetConfigModal(true) }}
            icon={(JSON.stringify(managedConfiguration, null, 2) == '{}') ? <PlusOutlined /> : <EditOutlined />}>
        </Button>

        <Card>
            <p>{JSON.stringify(managedConfiguration)}</p>
        </Card>

        <Modal
            title={"Managed configuration"}
            open={showSetConfigModal}
            centered
            closable
            footer={null}
            onCancel={() => setShowSetConfigModal(false)}
            okText={'Set'}
        >
            <Button onClick={() => setShowConfigTemplateModal(true)}>View Managed Config Template</Button>
            <Divider />
            <Text type="secondary">You can use custom attributes.</Text>
            <br />
            <Text type="secondary">i.e. %userid%, %username%, %deviceid%, %devicename%, %enterpriseid%, %grouppolicyid%</Text>
            <Form
                style={{ marginTop: 16 }}
                initialValues={{ 'configs': config }}
                onFinish={(values) => {
                    const configJson = {}
                    values.configs.forEach(e => {
                        let value = e.value
                        try {
                            if (value != null) {
                                if (value.toLowerCase() == 'true') {
                                    value = true
                                } else if (value.toLowerCase() == 'false') {
                                    value = false
                                } else if (!isNaN(value)) {
                                    value = Number(value)
                                }
                            }
                        } catch (error) {
                        }
                        configJson[e.key] = value
                    });
                    setManagedConfiguration(configJson)
                    setShowSetConfigModal(false)
                }}
                autoComplete="off"
            >
                <Form.List name="configs">
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map(({ key, name, ...restField }) => (
                                <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'key']}
                                        rules={[{ required: true, message: 'Missing key' }]}
                                    >
                                        <Input placeholder="Key" />
                                    </Form.Item>
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'value']}
                                        rules={[{ required: true, message: 'Missing value' }]}
                                    >
                                        <Input placeholder="Value" />
                                    </Form.Item>
                                    <MinusCircleOutlined onClick={() => remove(name)} />
                                </Space>
                            ))}
                            <Form.Item>
                                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                    Add field
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Save
                    </Button>
                </Form.Item>
            </Form>
        </Modal>

        <Modal
            title={"Managed configuration Template"}
            open={showConfigTemplateModal}
            centered
            closable
            footer={null}
            onCancel={() => setShowConfigTemplateModal(false)}
            okText={null}
        >
            <pre>{JSON.stringify(managedConfigAttrs, null, 2)}</pre>
        </Modal>

    </>
}

function NewDistributionComponent({ appDetails, onNewDistroCallback }) {
    const [openDistro, setOpenDistro] = useState(false);
    const [distroType, setDistroType] = useState(null);
    const [distroTargetId, setDistroTargetId] = useState(null);
    const [distroApiCallLoading, setDistroApiCallLoading] = useState(false);

    const [distroConfig, setDistroConfig] = useState({});

    return <div>
        <Modal title="New Distribution..."
            open={openDistro}
            closable
            maskClosable
            onCancel={(e) => setOpenDistro(false)}
            centered
            footer={[
                <Button loading={distroApiCallLoading} disabled={distroApiCallLoading} onClick={async () => {
                    if (distroTargetId == null || distroType == null) {
                        message.warning('Please fill up all details...')
                        return
                    }
                    setDistroApiCallLoading(true)
                    const result = await createAppDistributions(appDetails.id, JSON.stringify({ id: appDetails.id, type: distroType, targetId: distroTargetId, config: distroConfig }))
                    if (result && result.code == StatusCodes.CREATED) {
                        message.success('App distributed successfully')
                        setOpenDistro(false)
                        onNewDistroCallback()
                    } else {
                        message.error('Distribution failed! Error : ' + JSON.parse(result).message, 5)
                    }
                    setDistroApiCallLoading(false)
                }}><CheckOutlined />Distribute</Button>,
                <Button key="back" onClick={() => { setOpenDistro(false) }}>
                    Cancel
                </Button>,
            ]}
            width={'70%'}
        >
            <Form.Item label="Distribution Target">
                <Space>
                    <Select
                        labelInValue
                        placeholder={"Distribution type"}
                        style={{ width: 200 }}
                        defaultOpen={setDistroType.distroType}
                        onChange={(e) => { setDistroType(e.value) }}
                        options={Object.entries(DISTRO_TYPE).map((e) => {
                            // [key, value]
                            return { label: e[0], value: e[1] }
                        })}
                    />
                    <DistroOptionsSearchComponent distroType={distroType} setDistroTargetId={(e) => {
                        setDistroTargetId((e != null ? e.value : null))
                    }} />
                </Space>
            </Form.Item>
            <Form.Item>
                <NewDistributionConfigComponent appTracks={appDetails.resource.appTracks} managedConfigAttrs={appDetails.resource.managedProperties} updateDistroConfig={(configJson) => { setDistroConfig(configJson) }} />
            </Form.Item>
        </Modal>
        <Button
            type="primary"
            shape="round"
            disabled={openDistro}
            onClick={() => { setOpenDistro(true) }}
            icon={<UsergroupAddOutlined />}>
            New distribution
        </Button>
    </div >
}

function DistributionList({ appId, distroType, distributions, onDeleteDistroCallback }) {
    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);

    return <div>
        {(distributions == null || distributions.length == 0)
            ? <Empty description="No Distributions!" image={Empty.PRESENTED_IMAGE_SIMPLE} />
            : <List
                dataSource={distributions}
                renderItem={(item) => <DistributionListItem appId={appId} distroType={distroType} item={item} open={open} setOpen={setOpen} confirmLoading={confirmLoading} setConfirmLoading={setConfirmLoading} onDeleteDistroCallback={onDeleteDistroCallback} />}
            />
        }
    </div>
}

function DistributionListItem({ appId, distroType, item, open, setOpen, confirmLoading, setConfirmLoading, onDeleteDistroCallback }) {

    const onDeleteDistribution = (appId, type, item) => {
        return new Promise(async (resolve) => {
            setConfirmLoading(true);
            await deleteAppDistribution(appId, {
                id: item.id,
                type,
            }).then(() => {
                resolve(null)
                setOpen(false);
                setConfirmLoading(false);
                onDeleteDistroCallback()
            }).catch(() => {
                message.error('Failed to remove the distribution!')
            })
        })
    };

    return <div>
        <Popover
            title={item.name}
            content={<DistributionListItemInfoPopup appId={appId}
                distroType={distroType} deviceItem={item} />}
        >
            {item.name}
        </Popover>
        <Popconfirm
            title="Remove distribution?"
            description="Are you sure you want to remove this distribution? It will uninstall this app from concerned device as well..."
            okText="Yes"
            cancelText="No"
            onConfirm={() => onDeleteDistribution(appId, distroType, item)}
        >
            <CloseCircleFilled onClick={() => setOpen(true)} style={{ 'marginLeft': '10px' }} />
        </Popconfirm>
    </div>
}

function DistributionListItemInfoPopup({ appId, distroType, deviceItem }) {
    const [distroInfo, setDistroInfo] = useState(null);

    useEffect(async () => {
        setDistroInfo(await getAppDistributionDetails(appId, distroType, deviceItem.id))
    }, [])

    if (distroInfo) {
        return <>
            {(distroType == DISTRO_TYPE.DEVICE) && <p><b>{distroInfo.data.Device.User.fullname}</b></p>}
            <p>{JSON.stringify(distroInfo.data.resource, 4)}</p>
        </>
    } else {
        return <LoadingSpinner />
    }
}
