import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Form from "Components/SchemaForm";
import * as EditUserPageSchema from "./EditSchema.json";
import { getUserFromId, updateUser } from "utils/apis";
import Navigation from "Components/Navigation";
import { routes } from "Routes/routes";
import { Button, Space } from "antd";

export default function EditUserPage(props) {
  const [userData, setUserData] = useState(null);
  const [userSchema, setUserSchema] = useState({});
  let navigate = useNavigate();
  let loc = useLocation();
  const userId = loc.state.userId;

  useEffect(() => {
    getUserSchema(setUserSchema);
    getUser(userId, userData, setUserData);
  }, [userId, userData]);
  //navigate(routes.Admin.Create)
  // navigate(routes.DeviceRequest.Edit, { state: { deviceRequestId: item } });

  return (
    <div>
      <Navigation
        selectedKeys={["2"]}
        content={
          userData && userSchema ? (
            <Form
              formTitle="Update User"
              formData={userData}
              uiSchema={{
                DeviceRequests: {
                  "ui:options": {
                    addable: false,
                  },
                },
                Devices: {
                  "ui:options": {
                    addable: false,
                  },
                },
              }}
              schema={userSchema}
              onSubmitForm={(value) => onSubmitForm(userId, value, navigate)}
            />
          ) : (
            <div />
          )
        }
        addData={
          userData && userSchema ? (
            <Space size={20}>
              <Button
                type="primary"
                shape="round"
                onClick={() => {
                  navigate(routes.DeviceRequest.Create, {
                    state: { UserId: userData.id },
                  });
                }}
              >
                Add Device
              </Button>
              <Button
                type="primary"
                shape="round"
                onClick={() => {
                  const searchParams = new URLSearchParams();
                  searchParams.append("userId", userId);
                  navigate(
                    `${routes.User.ApplicationsList}?${searchParams.toString()}`
                  );
                }}
              >
                Edit Application
              </Button>
            </Space>
          ) : (
            <div />
          )
        }
      />
    </div>
  );
}

async function getUser(userId, userData, setUserData) {
  const response = await getUserFromId(userId);
  console.log("response : ", response);

  if (response) {
    if (!userData) {
      Object.keys(response.data).forEach((key) => {
        if (!response.data[key]) {
          delete response.data[key];
        }
      });
      setUserData(response.data);
    }
  }
}

async function getUserSchema(setUserSchema) {
  setUserSchema(EditUserPageSchema.default);
}

async function onSubmitForm(userId, requestBody, navigate) {
  console.log(requestBody);
  requestBody.AMDefaultPolicy = "";
  const response = await updateUser(userId, requestBody);
  console.log("response : ", response);
  navigate(routes.User.List);
}
