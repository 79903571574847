// import request from '../utils/request';
import request from "./request";
import { baseUrl } from "../constants";

//#region Auth Apis
async function loginUser(requestBody) {
  const requestURL = `${baseUrl}/v1/auth/sign-in`;
  var response = await request(requestURL, {
    method: "POST",
    body: JSON.stringify(requestBody),
  });
  localStorage.setItem("accessToken", response.accessToken);
  localStorage.setItem("admin", JSON.stringify(response.admin));
  return response;
}

async function verifySession() {
  try {
    const requestURL = `${baseUrl}/v1/auth/verify-session`;
    return await request(requestURL, { method: "GET" });
  } catch (ex) {
    console.log("Exception : ", ex);
  }
}

//#endregion

//#region Entity Apis

async function getEntityList(page, pageSize) {
  try {
    const requestURL = `${baseUrl}/v1/entity`;
    if (page && pageSize) requestURL += `&page=${page}&pageSize=${pageSize}`;
    return await request(requestURL, { method: "GET" });
  } catch (ex) {
    console.log("Exception ---->  ", ex);
  }
}

async function getEntitySelectionList(page, pageSize) {
  try {
    const requestURL = `${baseUrl}/v1/entity?min=true`;
    if (page && pageSize) requestURL += `&page=${page}&pageSize=${pageSize}`;
    return await request(requestURL, { method: "GET" });
  } catch (ex) {
    console.log("Exception ---->  ", ex);
  }
}

async function setEntity(EntityId) {
  try {
    const requestURL = `${baseUrl}/v1/entity/${EntityId}`;
    var response = await request(requestURL, { method: "PATCH" });
    localStorage.setItem("accessToken", response.accessToken);
    localStorage.setItem("admin", JSON.stringify(response.admin));
    return response;
  } catch (ex) {
    console.log("Exception ---->  ", ex);
  }
}

async function getEntityById(entityId) {
  try {
    const requestURL = `${baseUrl}/v1/entity/${entityId}`;
    return await request(requestURL, { method: "GET" });
  } catch (ex) {
    console.log("Exception : ", ex);
  }
}

export async function createEntity(requestBody) {
  try {
    const requestURL = `${baseUrl}/v1/entity`;
    return await request(requestURL, {
      method: "POST",
      body: JSON.stringify(requestBody),
    });
  } catch (ex) {
    console.log("Exception : ", ex);
  }
}

async function updateEntity(entityId, requestBody) {
  try {
    const requestURL = `${baseUrl}/v1/entity/${entityId}`;
    return await request(requestURL, {
      method: "PUT",
      body: JSON.stringify(requestBody),
    });
  } catch (ex) {
    console.log("Exception : ", ex);
  }
}

//#endregion

//#region Admin Apis

async function getAdminList(page, pageSize) {
  try {
    const requestURL = `${baseUrl}/v1/admin`;
    if (page && pageSize) requestURL += `&page=${page}&pageSize=${pageSize}`;
    return await request(requestURL, { method: "GET" });
  } catch (ex) {
    console.log("Exception ---->  ", ex);
  }
}

async function getAdminFromId(adminId) {
  try {
    const requestURL = `${baseUrl}/v1/admin/${adminId}`;
    return await request(requestURL, { method: "GET" });
  } catch (ex) {
    console.log("Exception : ", ex);
  }
}

async function createAdmin(requestBody) {
  try {
    const requestURL = `${baseUrl}/v1/admin`;
    var response = await request(requestURL, {
      method: "POST",
      body: JSON.stringify(requestBody),
    });
    return response;
  } catch (ex) {
    console.log("Exception : ", ex);
  }
}

async function updateAdmin(AdminId, requestBody) {
  try {
    const requestURL = `${baseUrl}/v1/admin/${AdminId}`;
    return await request(requestURL, {
      method: "PUT",
      body: JSON.stringify(requestBody),
    });
  } catch (ex) {
    console.log("Exception : ", ex);
  }
}

//#endregion

//#region User Apis

export async function getUserList(page, pageSize) {
  try {
    var requestURL = `${baseUrl}/v1/user?min=true`;
    if (page && pageSize) requestURL += `&page=${page}&pageSize=${pageSize}`;
    return await request(requestURL, { method: "GET" });
  } catch (ex) {
    console.log("Exception ---->  ", ex);
  }
}

export async function getAllUserList(searchValue) {
  try {
    var requestURL = `${baseUrl}/v1/user?min=true&page=1&pageSize=100000000`;
    const result = await request(requestURL, { method: "GET" });
    if (searchValue) {
      result.data = result.data.filter(
        (val) =>
          val.fullname.toLowerCase().includes(searchValue.toLowerCase()) ||
          val.username.toLowerCase().includes(searchValue.toLowerCase())
      );
      result.total = result.data.length;
    }
    return result;
  } catch (ex) {
    console.log("Exception ---->  ", ex);
  }
}

export async function getUserFromId(adminId) {
  try {
    const requestURL = `${baseUrl}/v1/user/id/${adminId}`;
    return await request(requestURL, { method: "GET" });
  } catch (ex) {
    console.log("Exception : ", ex);
  }
}

export async function createUser(requestBody) {
  try {
    const requestURL = `${baseUrl}/v1/user`;
    var response = await request(requestURL, {
      method: "POST",
      body: JSON.stringify(requestBody),
    });
    return response;
  } catch (ex) {
    console.log("Exception : ", ex);
  }
}

export async function createUserAppAssociation(requestBody) {
  try {
    const requestURL = `${baseUrl}/v1/amuserapps`;
    var response = await request(requestURL, {
      method: "POST",
      body: JSON.stringify(requestBody),
    });
    return response;
  } catch (ex) {
    console.log("Exception : ", ex);
  }
}

export async function updateUser(AdminId, requestBody) {
  try {
    const requestURL = `${baseUrl}/v1/user/${AdminId}`;
    return await request(requestURL, {
      method: "PUT",
      body: JSON.stringify(requestBody),
    });
  } catch (ex) {
    console.log("Exception : ", ex);
  }
}

export async function updateApplicationsFromUserId(
    userId,
    applicationId,
    requestBody
  ) {
    try {
      const requestURL = `${baseUrl}/v1/amuserapps/${userId}/${applicationId}`;
      const apiData = await request(requestURL, {
        method: "PUT",
        body: JSON.stringify(requestBody),
      });
      console.log(apiData);
      return apiData;
    } catch (ex) {
      console.log("Exception : ", ex);
    }
  }

export async function deleteUserAppAssociation(userId, applicationId) {
  try {
    const requestURL = `${baseUrl}/v1/amuserapps/${userId}/${applicationId}`;
    const apiData = await request(requestURL, { method: "DELETE" });
    console.log(apiData);
    return apiData;
  } catch (ex) {
    console.log("Exception : ", ex);
  }
}

export async function getApplicationsFromUserId(userId) {
  try {
    const requestURL = `${baseUrl}/v1/amuserapps/${userId}`;
    const apiData = await request(requestURL, { method: "GET" });
    console.log(apiData);
    return apiData;
  } catch (ex) {
    console.log("Exception : ", ex);
  }
}
//#endregion

//#region Device Apis

export async function getDeviceList(page, pageSize) {
  try {
    var requestURL = `${baseUrl}/v1/devices?min=true`;
    if (page && pageSize) requestURL += `&page=${page}&pageSize=${pageSize}`;
    return await request(requestURL, { method: "GET" });
  } catch (ex) {
    console.log("Exception ---->  ", ex);
  }
}

export async function getAllDeviceList(searchValue) {
  try {
    var requestURL = `${baseUrl}/v1/devices?min=true&page=1&pageSize=10000000`;
    const result = await request(requestURL, { method: "GET" });
    if (searchValue) {
      const searchValueLower = searchValue.toLowerCase();
      result.data = result.data.filter(
        (val) =>
          val.deviceName.toLowerCase().includes(searchValueLower) ||
          val.User.fullname.toLowerCase().includes(searchValue.toLowerCase()) ||
          val.User.username.toLowerCase().includes(searchValue.toLowerCase())
      );
      result.total = result.data.length;
    }
    console.log(result);
    return result;
  } catch (ex) {
    console.log("Exception ---->  ", ex);
  }
}

export async function findByDeviceName(searchQuery, limit) {
  try {
    var requestURL =
      `${baseUrl}/v1/devices/searchbydevicename?` +
      new URLSearchParams({ searchQuery, limit });
    return request(requestURL, { method: "GET" });
  } catch (ex) {
    console.log("Exception ---->  ", ex);
  }
}

export async function findByUserName(searchQuery, limit) {
  try {
    var requestURL =
      `${baseUrl}/v1/user/searchbyname?` +
      new URLSearchParams({ searchQuery, limit });
    return request(requestURL, { method: "GET" });
  } catch (ex) {
    console.log("Exception ---->  ", ex);
  }
}

export async function findByGroupName(searchQuery, limit) {
  try {
    var requestURL =
      `${baseUrl}/v1/groups/searchbyname?` +
      new URLSearchParams({ searchQuery, limit });
    return request(requestURL, { method: "GET" });
  } catch (ex) {
    console.log("Exception ---->  ", ex);
  }
}

export async function findByPolicyName(searchQuery, limit) {
  try {
    var requestURL =
      `${baseUrl}/v1/amgrouppolicy/searchbyname?` +
      new URLSearchParams({ searchQuery, limit });
    return request(requestURL, { method: "GET" });
  } catch (ex) {
    console.log("Exception ---->  ", ex);
  }
}

export async function getDeviceFromId(adminId) {
  try {
    const requestURL = `${baseUrl}/v1/devices/${adminId}`;
    const apiResp = await request(requestURL, { method: "GET" });
    console.log(apiResp);
    return apiResp;
  } catch (ex) {
    console.log("Exception : ", ex);
  }
}

export async function getApplicationsFromDeviceId(deviceId) {
  try {
    const requestURL = `${baseUrl}/v1/amdeviceapps/${deviceId}`;
    const apiData = await request(requestURL, { method: "GET" });
    console.log(apiData);
    return apiData;
  } catch (ex) {
    console.log("Exception : ", ex);
  }
}

export async function getDeviceAppAssociation(deviceId, applicationId) {
  try {
    const requestURL = `${baseUrl}/v1/amdeviceapps/${deviceId}/${applicationId}`;
    const apiData = await request(requestURL, { method: "GET" });
    console.log(apiData);
    return apiData;
  } catch (ex) {
    console.log("Exception : ", ex);
  }
}

export async function createDevice(requestBody) {
  try {
    const requestURL = `${baseUrl}/v1/devices`;
    var response = await request(requestURL, {
      method: "POST",
      body: JSON.stringify(requestBody),
    });
    return response;
  } catch (ex) {
    console.log("Exception : ", ex);
  }
}

export async function createDeviceAppAssociation(requestBody) {
  try {
    const requestURL = `${baseUrl}/v1/amdeviceapps`;
    var response = await request(requestURL, {
      method: "POST",
      body: JSON.stringify(requestBody),
    });
    return response;
  } catch (ex) {
    console.log("Exception : ", ex);
  }
}

export async function updateDevice(AdminId, requestBody) {
  try {
    const requestURL = `${baseUrl}/v1/devices/${AdminId}`;
    return await request(requestURL, {
      method: "PUT",
      body: JSON.stringify(requestBody),
    });
  } catch (ex) {
    console.log("Exception : ", ex);
  }
}

export async function sendPushNotification(
  deviceId,
  notificationMessage,
  requestBody
) {
  try {
    const requestURL = `${baseUrl}/v1/devices/sendpm`;
    var response = await request(requestURL, {
      method: "POST",
      body: JSON.stringify({
        deviceId: deviceId,
        fcmPmType: notificationMessage,
        payload: { ...requestBody },
      }),
    });
    return response;
  } catch (ex) {
    console.log("Exception : ", ex);
  }
}

export async function resetLockScreenPasswordForDevice(deviceId) {
  try {
    const requestURL = `${baseUrl}/v1/devices/${deviceId}/resetlockscreenpassword`;
    var response = await request(requestURL, { method: "POST" });
    return response;
  } catch (ex) {
    console.log("Exception : ", ex);
  }
}


export async function markDeviceAsLost(deviceId) {
  try {
    const requestURL = `${baseUrl}/v1/devices/${deviceId}/markaslost`;
    var response = await request(requestURL, { method: "POST" });
    return response;
  } catch (ex) {
    console.log("Exception : ", ex);
  }
}

export async function transferDeviceToDifferentUser(deviceId, userId) {
  try {
    const requestURL = `${baseUrl}/v1/devices/${deviceId}/transferdevice/${userId}`;
    return await request(requestURL, { method: "POST" });
  } catch (ex) {
    console.log("Exception : ", ex);
  }
}

export async function getPolicyToPreview(deviceId) {
  try {
    const requestURL = `${baseUrl}/v1/devices/${deviceId}/previewpolicy`;
    var response = await request(requestURL, { method: "GET" });
    return response;
  } catch (ex) {
    console.log("Exception : ", ex);
  }
}

export async function patchPolicy(deviceId) {
  try {
    const requestURL = `${baseUrl}/v1/devices/${deviceId}/patchpolicy`;
    var response = await request(requestURL, { method: "POST" });
    return response;
  } catch (ex) {
    console.log("Exception : ", ex);
  }
}

export async function deleteDevice(deviceId) {
  try {
    const requestURL = `${baseUrl}/v1/devices/${deviceId}`;
    var response = await request(requestURL, { method: "DELETE" });
    return response;
  } catch (ex) {
    console.log("Exception : ", ex);
  }
}
//CMD_RESET_DEVICE
export async function updateApplicationsFromDeviceId(
  deviceId,
  applicationId,
  requestBody
) {
  try {
    const requestURL = `${baseUrl}/v1/amdeviceapps/${deviceId}/${applicationId}`;
    const apiData = await request(requestURL, {
      method: "PUT",
      body: JSON.stringify(requestBody),
    });
    console.log(apiData);
    return apiData;
  } catch (ex) {
    console.log("Exception : ", ex);
  }
}

export async function deleteDeviceAppAssociation(deviceId, applicationId) {
  try {
    const requestURL = `${baseUrl}/v1/amdeviceapps/${deviceId}/${applicationId}`;
    const apiData = await request(requestURL, { method: "DELETE" });
    console.log(apiData);
    return apiData;
  } catch (ex) {
    console.log("Exception : ", ex);
  }
}

export async function generateWebTokenForManagedGooglePlayIframe() {
  try {
    const requestURL = `${baseUrl}/v1/apps/token`;
    const apiData = await request(requestURL, { method: "GET" });
    console.log({ apiData });
    return apiData.data.value;
  } catch (ex) {
    console.log("Exception : ", ex);
  }
}

//#endregion

//#region Policy Apis

export async function getPolicyList(page, pageSize) {
  try {
    const requestURL = `${baseUrl}/v1/amgrouppolicy?min=true`;
    if (page && pageSize) requestURL += `&page=${page}&pageSize=${pageSize}`;
    return await request(requestURL, { method: "GET" });
  } catch (ex) {
    console.log("Exception ---->  ", ex);
  }
}
export async function getPolicyFromId(adminId) {
  try {
    const requestURL = `${baseUrl}/v1/amgrouppolicy/${adminId}`;
    return await request(requestURL, { method: "GET" });
  } catch (ex) {
    console.log("Exception : ", ex);
  }
}
export async function getApplicationsFromPolicyId(policyId) {
  try {
    const requestURL = `${baseUrl}/v1/amgrouppolicyapps/${policyId}`;
    const apiData = await request(requestURL, { method: "GET" });
    console.log(apiData);
    return apiData;
  } catch (ex) {
    console.log("Exception : ", ex);
  }
}

export async function getFilteredApplicationsFromPolicyId(
  policyId,
  searchValue
) {
  try {
    const requestURL = `${baseUrl}/v1/amgrouppolicyapps/${policyId}`;
    const apiData = await request(requestURL, { method: "GET" });
    console.log(apiData);
    return apiData;
  } catch (ex) {
    console.log("Exception : ", ex);
  }
}

export async function getPolicyAppAssociation(policyId, applicationId) {
  try {
    const requestURL = `${baseUrl}/v1/amgrouppolicyapps/${policyId}/${applicationId}`;
    const apiData = await request(requestURL, { method: "GET" });
    console.log(apiData);
    return apiData;
  } catch (ex) {
    console.log("Exception : ", ex);
  }
}
export async function createPolicy(requestBody) {
  try {
    const requestURL = `${baseUrl}/v1/amgrouppolicy`;
    var response = await request(requestURL, {
      method: "POST",
      body: JSON.stringify(requestBody),
    });
    return response;
  } catch (ex) {
    console.log("Exception : ", ex);
  }
}

export async function createPolicyAppAssociation(requestBody) {
  try {
    const requestURL = `${baseUrl}/v1/amgrouppolicyapps`;
    var response = await request(requestURL, {
      method: "POST",
      body: JSON.stringify(requestBody),
    });
    return response;
  } catch (ex) {
    console.log("Exception : ", ex);
  }
}

export async function createAMApplication(requestBody) {
  const requestURL = `${baseUrl}/v1/amapps/packagename`;
  return request(requestURL, {
    method: "POST",
    body: JSON.stringify(requestBody),
  });
}

export async function updatePolicy(AdminId, requestBody) {
  try {
    const requestURL = `${baseUrl}/v1/amgrouppolicy/${AdminId}`;
    return await request(requestURL, {
      method: "PUT",
      body: JSON.stringify(requestBody),
    });
  } catch (ex) {
    console.log("Exception : ", ex);
  }
}

export async function sendPolicyGroupPushNotification(
  policyId,
  notificationMessage,
  requestBody
) {
  try {
    const requestURL = `${baseUrl}/v1/amgrouppolicy/sendpm`;
    var response = await request(requestURL, {
      method: "POST",
      body: JSON.stringify({
        AMGroupPolicyId: policyId,
        fcmPmType: notificationMessage,
        payload: { ...requestBody },
      }),
    });
    return response;
  } catch (ex) {
    console.log("Exception : ", ex);
  }
}

export async function updateApplicationsFromPolicyId(
  policyId,
  applicationId,
  requestBody
) {
  try {
    const requestURL = `${baseUrl}/v1/amgrouppolicyapps/${policyId}/${applicationId}`;
    const apiData = await request(requestURL, {
      method: "PUT",
      body: JSON.stringify(requestBody),
    });
    console.log(apiData);
    return apiData;
  } catch (ex) {
    console.log("Exception : ", ex);
  }
}

export async function deletePolicyAppAssociation(policyId, applicationId) {
  try {
    const requestURL = `${baseUrl}/v1/amgrouppolicyapps/${policyId}/${applicationId}`;
    const apiData = await request(requestURL, { method: "DELETE" });
    console.log(apiData);
    return apiData;
  } catch (ex) {
    console.log("Exception : ", ex);
  }
}

//#endregion

//#region AMApplications Apis
export async function getAMApplicationList(page, pageSize) {
  try {
    var requestURL = `${baseUrl}/v1/amapps?min=true`;
    if (page && pageSize)
      requestURL += `&page=${page}&pageSize=${pageSize}&title=[uu]`;
    return await request(requestURL, { method: "GET" });
  } catch (ex) {
    console.log("Exception ---->  ", ex);
  }
}

export async function getAllAMApplicationList(searchValue) {
  try {
    var requestURL = `${baseUrl}/v1/amapps?min=true&page=1&pageSize=1000000`;
    const result = await request(requestURL, { method: "GET" });
    if (searchValue) {
      result.data = result.data.filter(
        (val) =>
          val.packageName.toLowerCase().includes(searchValue.toLowerCase()) ||
          val.title.toLowerCase().includes(searchValue.toLowerCase())
      );
      result.total = result.data.length;
    }
    console.log(result);
    return result;
  } catch (ex) {
    console.log("Exception ---->  ", ex);
  }
}

export async function getAppDetails(appId) {
  try {
    var requestURL = `${baseUrl}/v1/amapps/${appId}`;
    const result = await request(requestURL, { method: "GET" });
    return result;
  } catch (ex) {
    console.log("Exception ---->  ", ex);
  }
}

export async function refreshAppDetails(appId) {
  try {
    var requestURL = `${baseUrl}/v1/amapps/${appId}/refreshappinfo`;
    const result = await request(requestURL, { method: "POST" });
    return result;
  } catch (ex) {
    console.log("Exception ---->  ", ex);
  }
}

export async function getAppDistributions(appId) {
  try {
    var requestURL = `${baseUrl}/v1/amapps/${appId}/distributions`;
    const result = await request(requestURL, { method: "GET" });
    return result;
  } catch (ex) {
    console.log("Exception ---->  ", ex);
  }
}

export async function getAppDistributionDetails(
  appId,
  distroType,
  distroTargetId
) {
  try {
    var requestURL = `${baseUrl}/v1/amapps/${appId}/distributions/info?type=${distroType}&targetId=${distroTargetId}`;
    const result = await request(requestURL, { method: "GET" });
    return result;
  } catch (ex) {
    console.log("Exception ---->  ", ex);
  }
}

export async function createAppDistributions(appId, jsonBody) {
  try {
    var requestURL = `${baseUrl}/v1/amapps/${appId}/distributions`;
    return await request(requestURL, { method: "POST", body: jsonBody });
  } catch (ex) {
    console.log("Exception ---->  ", ex);
    return ex.message;
  }
}

export async function deleteAppDistribution(appId, distributions) {
  try {
    var requestURL = `${baseUrl}/v1/amapps/${appId}/distributions`;
    const result = await request(requestURL, {
      method: "DELETE",
      body: JSON.stringify(distributions),
    });
    return result;
  } catch (ex) {
    console.log("Exception ---->  ", ex);
  }
}

export async function getAppInstallations(appId) {
  try {
    var requestURL = `${baseUrl}/v1/amapps/${appId}/installations`;
    const result = await request(requestURL, { method: "GET" });
    return result;
  } catch (ex) {
    console.log("Exception ---->  ", ex);
  }
}

export async function getAll(func, pageSize = 100) {
  var page = 1;
  var total = 0;
  var data = [];
  try {
    do {
      var response = await func(page, pageSize);
      data.push(...response.data);
      total = response.total;
      page++;
    } while (total > data.length);
    return { data };
  } catch (ex) {
    console.log("Exception ---->  ", ex);
  }
}

//#endregion

//#region Device Request Apis

export async function getDeviceRequestList(page, pageSize) {
  try {
    const requestURL = `${baseUrl}/v1/devicerequests`;
    if (page && pageSize) requestURL += `&page=${page}&pageSize=${pageSize}`;
    return await request(requestURL, { method: "GET" });
  } catch (ex) {
    console.log("Exception ---->  ", ex);
  }
}
export async function getDeviceRequestFromId(adminId) {
  try {
    const requestURL = `${baseUrl}/v1/devices/${adminId}`;
    const resp = await request(requestURL, { method: "GET" });
    console.log(resp);
    return resp;
  } catch (ex) {
    console.log("Exception : ", ex);
  }
}

export async function createDeviceRequest(requestBody) {
  try {
    const requestURL = `${baseUrl}/v1/devicerequests`;
    var response = await request(requestURL, {
      method: "POST",
      body: JSON.stringify(requestBody),
    });
    return response;
  } catch (ex) {
    console.log("Exception : ", ex);
  }
}

export async function updateDeviceRequest(AdminId, requestBody) {
  try {
    const requestURL = `${baseUrl}/v1/devices/${AdminId}`;
    return await request(requestURL, {
      method: "PUT",
      body: JSON.stringify(requestBody),
    });
  } catch (ex) {
    console.log("Exception : ", ex);
  }
}
//#endregion

//#region Group Requests Apis

export async function getAllGroups(searchValue) {
  try {
    var requestURL = `${baseUrl}/v1/groups?page=1&pageSize=10000000`;
    const result = await request(requestURL, { method: "GET" });
    // if (searchValue) {
    //     const searchValueLower = searchValue.toLowerCase();
    //     result.data = result.data.filter(val =>
    //     (
    //         val.name.toLowerCase().includes(searchValueLower) ||
    //         val.desctiption.toLowerCase().includes(searchValueLower)
    //     ));
    //     result.total = result.data.length;
    // }
    // console.log(result)
    return result;
  } catch (ex) {
    console.log("Exception ---->  ", ex);
  }
}

//#endregion
const functions = {
  loginUser,
  getEntityList,
  getEntitySelectionList,
  setEntity,
  getEntityById,
  createEntity,
  updateEntity,
  getAdminList,
  getAdminFromId,
  createAdmin,
  updateAdmin,
  getUserList,
  getAllUserList,
  getUserFromId,
  createUser,
  updateUser,
  getDeviceList,
  getDeviceFromId,
  createDevice,
  updateDevice,
  sendPushNotification,
  getPolicyList,
  getPolicyFromId,
  getApplicationsFromPolicyId,
  getPolicyAppAssociation,
  updateApplicationsFromPolicyId,
  createPolicy,
  createPolicyAppAssociation,
  createAMApplication,
  updatePolicy,
  deletePolicyAppAssociation,
  createDeviceRequest,
  verifySession,
  getAMApplicationList,
  getAllAMApplicationList,
  getAll,
  getAllGroups,
};

export default functions;
