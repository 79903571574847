
import React, { useState } from "react";
import {
    Table, Button
} from 'antd';
import { stateToHTML } from "draft-js-export-html"
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import HTMLTextEditor from 'Components/HTMLTextEditor'

export default function SchemaTable(props) {
    var columns = JSON.parse(JSON.stringify(props.tableSchema)).filter((val) => !val.hidden).map((field) => {
        return field.fixed ?
            {
                title: field.name,
                dataIndex: field.dataIndex,
                fixed: field.fixed
            }
            :
            {
                title: field.name,
                dataIndex: field.dataIndex,
            }
    });

    const [editorState, setEditorState] = React.useState(
        EditorState.createEmpty()
    );


    const handleEditorChange = (state) => {
        setEditorState(state);
        convertContentToHTML();
    }


    const convertContentToHTML = () => {
        let currentContentAsHTML = stateToHTML(editorState.getCurrentContent());
        console.log(currentContentAsHTML)
    }

    if (props.hasEditAction || props.hasDeleteAction) {
        columns.push({
            title: "Action",
            fixed: 'right',
            width: '14',
            dataIndex: props.actionDataIndex,
            render: (item) => {
                return <div>
                    {props.hasEditAction ? <Button type='link' onClick={() => props.onEdit(item)} > Edit</Button > : <div />}
                    {props.hasDeleteAction ? <Button danger type='link' onClick={() => props.onDelete(item)} > Delete</Button > : <div />}
                </div>
            }
        })
    }

    return (
        <div>
            < Table dataSource={props.tableData}
                columns={columns}
                rowKey="id"
                scroll={{ x: 800, y: 700 }}
                onRow={(record, rowIndex) => {
                    if (props.onRowClick) {
                        return {
                            onClick: () => { props.onRowClick(props.tableData[rowIndex]) },
                        };
                    }
                }}
                {...props.options}
            />
        </div >
    );
};

// <HTMLTextEditor onUpdate={(val) => { console.log(val) }} />