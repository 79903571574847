import React, { useEffect, useState } from "react";
import { Button, PageHeader, Breadcrumb } from 'antd';
import { useNavigate, useLocation } from "react-router-dom";
import Navigation from "Components/Navigation";
// import * as EntityListPageDummyData from '../DummyData/EntityListPageData.json';
import GenericTable from 'Components/GenericTable'
import apis from 'utils/apis';

export default function DashboardPage() {
    let navigate = useNavigate();
    let loc = useLocation();
    // TODO if state is not set redirect to login page
    const admin = localStorage.getItem('admin');
    const EntityId = admin.EntityId;
    console.log(EntityId)
    // useEffect(() => getEntityList(setEntityListData), [])



    const breadcrumb = ["Dashboard"]
    const content = (<div>
        <Breadcrumb style={{ margin: '6px 0' }}>
            {breadcrumb.map((item, i) => { return <Breadcrumb.Item key={i}>{item}</Breadcrumb.Item> })}
        </Breadcrumb>
        <div className="site-layout-content">Coming soon...</div>
    </div>)

    return (
        <div>
            <Navigation
                selectedKeys={['0']}
                content={content}
            />
        </div>
    );
}
