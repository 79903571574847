import React, { useEffect, useState } from "react";
import { Button } from 'antd';
import { useNavigate } from "react-router-dom";
import Navigation from "Components/SANavigation";
import * as AdminListPageJson from './ListSchema.json';
import SchemaTable from 'Components/SchemaTable'
import apis from 'utils/apis';
import { routes } from 'Routes/routes'

export default function AdminListPage() {
    let navigate = useNavigate();
    const [adminListData, setAdminListData] = useState({});

    useEffect(() => {
        getAdminList();
    }, []);

    async function getAdminList(page, pageSize) {
        var response = await apis.getAdminList(page, pageSize);
        setAdminListData({
            data: response.data,
            total: response.total
        });
    }

    const onEdit = (item) => {
        navigate(routes.Admin.Edit, { state: { adminId: item } });
    };

    var pagination = { position: ['bottomLeft'], total: adminListData.total, onChange: getAdminList }

    return (
        <div>
            <Navigation
                selectedKeys={['1']}
                content={
                    adminListData.length === 0 ? <div /> : < SchemaTable tableSchema={AdminListPageJson.default} tableData={adminListData.data} actionDataIndex="id" onEdit={onEdit}

                        options={{ pagination }}
                    />
                }
                addData={
                    <Button type="primary" shape="round" onClick={() => { console.log("add Admin"); navigate(routes.Admin.Create) }}>
                        Add Admin
                    </Button>
                }
            />
        </div>
    );
}

