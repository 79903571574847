import React, { useEffect, useState } from "react";
import { Button, Input } from 'antd';
import { useNavigate } from "react-router-dom";
import Navigation from "Components/Navigation";
import * as DeviceListPageJson from './ListSchema.json';
import SchemaTable from 'Components/SchemaTable'
import { getDeviceList, getAllDeviceList } from 'utils/apis';
import { routes } from 'Routes/routes'
import { getRelativeTime } from "utils/utils";

export default function DeviceListPage() {
    let navigate = useNavigate();
    const [deviceListData, setDeviceListData] = useState([]);
    const [searchvalue, setSearchValue] = useState('');

    useEffect(() => { getDevices() }, [searchvalue])

    const onEdit = (item) => {
        navigate(routes.Device.Edit, { state: { deviceId: item } });
    };

    async function getDevices(page, pageSize) {
        var response = await getAllDeviceList(searchvalue);
        setDeviceListData({
            data: response.data.map(val => {
                return {
                    ...val,
                    username: val.User.username,
                    fullname: val.User.fullname,
                    updatedAt: getRelativeTime(new Date(val.updatedAt)),
                }
            }),
            total: response.total
        });
    }

    var pagination = { position: ['bottomLeft'], total: deviceListData.total, onChange: getDevices }

    return (
        <div>
            <Navigation
                selectedKeys={['3']}
                content={
                    deviceListData.length === 0 ? <div /> : < SchemaTable tableSchema={DeviceListPageJson.default} tableData={deviceListData.data} actionDataIndex="id" hasEditAction={true} onEdit={onEdit} options={{ pagination }} />
                }
                addDataLeft={
                    <Input placeholder="Search Name" value={searchvalue} onChange={(e) => setSearchValue(e.target.value)} style={{ width: '60vh' }} />
                }
                addData={
                    <Button type="primary" shape="round" onClick={() => { navigate(routes.Device.Create) }}>
                        Add Device
                    </Button>
                }
            />
        </div>
    );
}


