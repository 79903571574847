import React, { useEffect, useState } from "react";
import { Button } from 'antd';
import { useNavigate } from "react-router-dom";
import Navigation from "Components/Navigation";
import DeviceRequestListPageJson from './ListSchema.json';
import { getDeviceRequestList } from 'utils/apis';
import { routes } from 'Routes/routes'
import SchemaTable from "Components/SchemaTable";

export default function DeviceRequestListPage() {
    let navigate = useNavigate();
    const [deviceRequestListData, setDeviceRequestListData] = useState([]);
    useEffect(() => getDeviceRequests(setDeviceRequestListData), [])

    const onEdit = (item) => {
        navigate(routes.DeviceRequest.Edit, { state: { deviceRequestId: item } });
    };

    async function getDeviceRequests(page, pageSize) {
        var response = await getDeviceRequestList(page, pageSize);
        setDeviceRequestListData({
            data: response.data,
            total: response.total
        });
    }

    var pagination = { position: ['bottomLeft'], total: deviceRequestListData.total, onChange: getDeviceRequests }

    return (
        <div>
            <Navigation
                selectedKeys={['2']}
                content={
                    deviceRequestListData.length === 0 ? <div /> : < SchemaTable tableSchema={DeviceRequestListPageJson} tableData={deviceRequestListData.data} actionDataIndex="id" hasEditAction={true} onEdit={onEdit} options={{ pagination }} />
                }
                addData={
                    <Button type="primary" shape="round" onClick={() => { navigate(routes.DeviceRequest.Create) }}>
                        Add DeviceRequest
                    </Button>
                }
            />
        </div>
    );
}



