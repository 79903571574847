import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Form from 'Components/SchemaForm'
import * as CreateEntityPageSchema from './CreateSchema.json';
import { createEntity } from 'utils/apis';
import Navigation from "Components/SANavigation";
import { routes } from 'Routes/routes'

export default function CreateEntityPage(props) {
    let navigate = useNavigate();
    const [createEntitySchema, setCreateEntitySchema] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            console.log(CreateEntityPageSchema.default);
            setCreateEntitySchema(CreateEntityPageSchema.default);
        }
        fetchData().catch(console.error);
    }, [])

    return (
        <div>
            <Navigation
                selectedKeys={['0']}
                content={
                    createEntitySchema ? <Form formTitle="Create Entity" schema={createEntitySchema} onSubmitForm={(value) => onSubmitForm(value, navigate)} /> : <div />
                }
            />
        </div>
    );
};

async function onSubmitForm(requestBody, navigate) {
    console.log(requestBody);
    const response = await createEntity(requestBody);
    console.log("response : ", response);
    navigate(routes.Entity.List);
};
