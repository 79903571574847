import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Navigation from "Components/Navigation";
import InstalledApplicationListPageJson from './InstalledApplicationListSchema.json';
import SchemaTable from 'Components/SchemaTable'

export default function InstalledApplicationList() {
    let loc = useLocation();
    const installedApps = loc.state.installedApps;
    console.log("installedApps : ", installedApps);
    return (
        <div>
            <Navigation
                selectedKeys={['3']}
                content={
                    installedApps.length === 0 || InstalledApplicationListPageJson.length === 0 ? <div /> : < SchemaTable tableSchema={InstalledApplicationListPageJson} tableData={installedApps} />
                }
            />
        </div >
    );
}
