import { AppstoreAddOutlined } from "@ant-design/icons";
import LoadingSpinner from "Components/LoadingSpinner";
import { Button, Form, Input, Modal, Radio, message } from "antd";
import { StatusCodes } from "http-status-codes";
import _ from "lodash";
import { useEffect, useState } from "react";
import { createAMApplication, generateWebTokenForManagedGooglePlayIframe } from "utils/apis";

export default function AddNewAppComponent() {
    const [showAddDialog, setShowAddDialog] = useState(false);
    return <>
        <Button disabled={showAddDialog} onClick={() => { setShowAddDialog(true) }} ><AppstoreAddOutlined />Add new App</Button>
        {showAddDialog && <AddAppDialog closeModal={() => setShowAddDialog(false)} />}
    </>

}

function AddAppDialog({ closeModal }) {

    const [form] = Form.useForm();
    const appTypeOptions = [
        { label: 'Google Play', value: 'GOOGLE_PLAY' },
        { label: 'System App (OS/Phone default)', value: 'SYSTEM' },
        { label: 'Private (Direct APK, etc)', value: 'PRIVATE' }
    ];

    const [appType, setAppType] = useState();
    const [packageName, setPackageName] = useState();
    const [title, setTitle] = useState();
    const [showGooglePlayAppSelectionModal, setShowGooglePlayAppSelectionModal] = useState(false);

    async function createApplication() {

        if (!appType) {
            message.warning('Please select the Type of App');
            return
        }
        if (!packageName) {
            message.warning('Please select an App');
            return
        }
        if ((!title && ["SYSTEM", "PRIVATE"].includes(appType))) {
            message.warning('Please fill up the App Title');
            return;
        }

        const requestBody = {
            "packageName": packageName,
            "appType": appType,
            "title": title ?? ''
        }

        createAMApplication(requestBody).then((res) => {
            if (res && res.code === StatusCodes.CREATED) {
                message.success('App added successfully.');
                closeModal()
            } else {
                message.warning(res.message);
            }
        }).catch((e) => {
            message.error(JSON.parse(e.message).message);
        });

    }

    return <>
        <Modal
            // bodyStyle={{ height: '50vw' }}
            width={'50%'}
            open={true}
            title={'Add new App'}
            centered
            okText={"Add App"}
            onOk={() => {
                createApplication()
            }}
            onCancel={() => closeModal()}
        >
            <>
                <Form form={form}>
                    <Form.Item
                        label="App Type"
                        rules={[{ required: true, },]}
                    >
                        <Radio.Group options={appTypeOptions} onChange={(e) => {
                            setAppType(e.target.value)
                            setPackageName(null)
                            setTitle(null)
                            setShowGooglePlayAppSelectionModal(false)
                        }} value={appType} optionType="button" buttonStyle="solid" />
                    </Form.Item>

                    {(appType != null) && <>
                        <Form.Item label="Package Name" rules={[{ required: true, },]}>
                            <Input placeholder="Enter PackageName" value={packageName} onChange={(e) => setPackageName(e.target.value)} />
                        </Form.Item>

                        {["SYSTEM", "PRIVATE"].includes(appType) && <>
                            <Form.Item label="App Title" rules={[{ required: true, },]}>
                                <Input placeholder="Enter Application Title" value={title} onChange={(e) => setTitle(e.target.value)} />
                            </Form.Item>
                        </>}

                        {["GOOGLE_PLAY"].includes(appType) && <>
                            <Button onClick={() => {
                                setShowGooglePlayAppSelectionModal(true)
                            }}>Select From Play store</Button>

                            {showGooglePlayAppSelectionModal && <>
                                <AddGooglePlayAppComponent onAppSelected={(packageName) => {
                                    setShowGooglePlayAppSelectionModal(false)
                                    setPackageName(packageName)
                                }} />
                            </>}
                        </>}
                    </>}
                </Form>
            </>
        </Modal>
    </>
}

function AddGooglePlayAppComponent({ onAppSelected }) {

    const [webToken, setWebToken] = useState(null)

    useEffect(async () => { setWebToken(await generateWebTokenForManagedGooglePlayIframe()) }, [])

    if (!webToken) { return <p>Loading...</p> }

    return <GooglePlayIframeComponent webToken={webToken} onAppSelected={onAppSelected} />
}

/**
 * https://developers.google.com/android/management/apps#add_the_iframe_to_your_console
 */
function GooglePlayIframeComponent({ webToken, onAppSelected }) {
    const [showGPlayIframModal, setShowGPlayIframModal] = useState(true)

    useEffect(() => {
        const googlePlayUrl = 'https://play.google.com/work/embedded/search?token=' + webToken + '&mode=SELECT&showsearchbox=TRUE'

        const script = document.createElement("script");
        script.src = "https://apis.google.com/js/api.js";

        script.onload = () => {
            window.gapi.load('gapi.iframes', function () {
                var options = {
                    'url': googlePlayUrl,
                    'where': document.getElementById('gplay_container'),
                    'attributes': { style: { height: '48vw' } }
                }

                var iframe = window.gapi.iframes.getContext().openChild(options);
                iframe.register('onproductselect', function (e) {
                    if (e.action == 'selected') {
                        onAppSelected(e.packageName)
                        setShowGPlayIframModal(false)
                    }
                }, window.gapi.iframes.CROSS_ORIGIN_IFRAMES_FILTER);
            });
        }
        document.body.appendChild(script);
    }, [])
    return <>
        <Modal
            bodyStyle={{ height: '50vw' }}
            width={'80%'}
            open={showGPlayIframModal}
            centered
            footer={null}
            onCancel={() => setShowGPlayIframModal(false)}
        >
            <div id="gplay_container" />
        </Modal>
    </>
}