import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { getAppDetails, refreshAppDetails } from "utils/apis";
import AppBasicDetails from "./components/AppBasicDetails";
import LoadingSpinner from "Components/LoadingSpinner";
import { Avatar, Collapse, Dropdown, Space, Typography, message } from "antd";
import CollapsePanel from "antd/lib/collapse/CollapsePanel";
import AppDistributionDetails from "./components/AppDistributionDetails";
import AppInstallations from "./components/AppInstallations";
import Navigation from "Components/Navigation";
import { AndroidOutlined, LinkOutlined, MenuOutlined } from "@ant-design/icons";
import Title from "antd/lib/typography/Title";
const { Text, Link } = Typography;

export default function ApplicationsDetailsPage(props) {

    const appId = useLocation().state.appId;

    const [appInfo, setAppInfo] = useState(null);

    useEffect(() => { fetchAppInfo() }, []);

    async function fetchAppInfo() {
        const appData = (await getAppDetails(appId)).data
        setAppInfo(appData);
    }
    function getComponent() {
        return <>
            <Space style={{ width: '100%' }}>
                {(appInfo.resource != undefined && appInfo.resource.smallIconUrl != undefined) && <Avatar src={appInfo.resource.smallIconUrl} />}
                {(appInfo.resource == undefined || appInfo.resource.smallIconUrl == undefined) && <Avatar icon={<AndroidOutlined />} />}
                <Title level={3}>{appInfo.title}</Title>
                <Text type="secondary">{appInfo.resource.author}</Text>
                <Link href={appInfo.resource.playStoreUrl} target="_blank"> <LinkOutlined /></Link>
            </Space>
            <Collapse bordered defaultActiveKey={['summary', 'distributions']}>
                <CollapsePanel header="App Summary" key={"summary"}>
                    <AppBasicDetails appDetails={appInfo} />
                </CollapsePanel>
                <CollapsePanel header="Installation Summary" key={"installations"} >
                    <AppInstallations appId={appId} />
                </CollapsePanel>
                <CollapsePanel header="Distributions" key={"distributions"}>
                    <AppDistributionDetails appDetails={appInfo} />
                </CollapsePanel>
            </Collapse>
        </>
    }

    const menuItems = [
        {
            label: 'Refresh App Details',
            key: 'refresh_app_details',
            onClick: async () => {
                const response = await refreshAppDetails(appId)
                if (response) {
                    message.success('Successfully refreshed app details.');
                    fetchAppInfo()
                } else {
                    message.error('Failed to fetch app details!');
                }
            }
        },
    ];

    if (appInfo) {
        return (
            <div>
                <Navigation
                    selectedKeys={['5']}
                    content={getComponent()}
                    addData={
                        <Dropdown menu={{ items: menuItems }}>
                            <a><MenuOutlined /></a>
                        </Dropdown>
                    }
                >
                </Navigation>
            </div >
        );
    } else {
        return <LoadingSpinner loadingText={"loading..."} />
    }
}
