import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import Form from 'Components/SchemaForm'
import * as EditEntityPageSchema from './EditSchema.json';
import apis from 'utils/apis';
import Navigation from 'Components/SANavigation';
import { routes } from 'Routes/routes'

export default function EditEntityPage(props) {
    const [entityData, setEntityData] = useState(null);
    const [entitySchema, setEntitySchema] = useState({});

    let navigate = useNavigate();
    let loc = useLocation();
    var entityId = loc.state.entityId

    useEffect(() => {
        getEntitySchema(setEntitySchema);
        getEntity(entityId, entityData, setEntityData);
    }, [entityId, entityData]);

    return (
        <div>
            <Navigation
                selectedKeys={['0']}
                content={
                    entityData && entitySchema ? <Form formTitle="Update Entity" formData={entityData} schema={entitySchema} onSubmitForm={(value) => onSubmitForm(entityId, value, navigate)} /> : <div />
                }
            />
        </div>
    );
};

async function getEntity(entityId, entityData, setEntityData) {
    var response = await apis.getEntityById(entityId);
    if (response) {
        if (!entityData) setEntityData(response.data);
    }
}

async function getEntitySchema(setEntitySchema) {
    setEntitySchema(EditEntityPageSchema.default);
}

async function onSubmitForm(entityId, requestBody, navigate) {
    console.log(requestBody)
    const response = await apis.updateEntity(entityId, requestBody);
    console.log(response)
    navigate(routes.Entity.List);
};