import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import * as EditDevicePageSchema from './EditSchema.json';
import { getDeviceFromId, updateDevice, sendPushNotification, resetLockScreenPasswordForDevice, deleteDevice, patchPolicy, getPolicyToPreview, transferDeviceToDifferentUser, markDeviceAsLost } from 'utils/apis';
import { routes } from 'Routes/routes'
import MultiSelectSchemaForm from 'Components/MultiSelectSchemaForm'
import { Button, Space, Menu, Dropdown, Select, Modal, Input, Row, Col, Typography, message } from 'antd';
import { CloseCircleOutlined, CopyOutlined, CheckOutlined } from '@ant-design/icons';
import { getRelativeTime } from 'utils/utils';

const { Option } = Select;
const { Title } = Typography;


export default function EditDevicePage(props) {

    const pushNotificationOptions = [
        'CMD_SYNC_CONTROL_SDK_POLICY',
        'CMD_ACTIVATE_KNOX_LICENSE',
        'CMD_SYNC_FCM_TOKEN',
        'CMD_SYNC_AM_POLICY',
        'INFO_DEVICE_NAME_UPDATED',
        'INFO_MSG',
        'CMD_REPORT_LOGGED_IN_ACCOUNTS',
        'CMD_SET_ONLY_ALLOWED_GOOGLE_ACCOUNTS_IN_PLAY_STORE',
        'CMD_SET_ALL_GOOGLE_ACCOUNTS_IN_PLAY_STORE',
        'CMD_LOST_DEVICE_BUZZ_ALERT',
        'CMD_KNOX_REMOVE_ACCOUNTS_BY_TYPE',
        'CMD_KNOX_CLEAR_APPS_ALLOW_BLOCK_LIST'
    ]

    const menu = (
        <Menu onClick={(e) => handleMenuClick(e.key)} style={{ width: '200px' }}>
            <Menu.Item key="1" >
                View Installed Apps
            </Menu.Item>
            <Menu.Item key="2" >
                Edit Applications
            </Menu.Item>
            <Menu.Item key="3" >
                Send Push Notification
            </Menu.Item>
            <Menu.Item key="4" >
                Delete Device
            </Menu.Item>
            <Menu.Item key="5" >
                Generate Debug Mode Code
            </Menu.Item>
            <Menu.Item key="6" >
                Reset lock screen password
            </Menu.Item>
            <Menu.Item key="7" >
                Preview policy
            </Menu.Item>
            <Menu.Item key="8" >
                Patch policy
            </Menu.Item>
            <Menu.Item key="9" >
                Transfer Device to another user
            </Menu.Item>
            <Menu.Item key="10" >
                Mark as LOST
            </Menu.Item>
        </Menu >
    );
    const [deviceData, setDeviceData] = useState(null);
    const [deviceSchema, setDeviceSchema] = useState(null);
    const [masterDeviceSchema, setMasterDeviceSchema] = useState();
    const [deviceDeletionConfirmationModalVisibility, setDeviceDeletionConfirmationModalVisibility] = useState(false);
    const [deviceDeletionSuccessModalVisibility, setDeviceDeletionSuccessModalVisibility] = useState(false);
    const [visible, setVisible] = useState(false);
    const [visibleDubugModeCode, setVisibleDubugModeCode] = useState(false);
    const [selectedPushNotification, setSelectedPushNotification] = useState(pushNotificationOptions[0]);
    const [infoTitle, setInfoTitle] = useState('');
    const [infoMessage, setInfoMessage] = useState('');
    const [appToOpen, setAppToOpen] = useState('');
    const [urlToOpen, setUrlToOpen] = useState('');
    const [accountType, setAccountType] = useState('');

    var updatedDeviceData = null;
    let navigate = useNavigate();
    let loc = useLocation();
    const deviceId = loc.state.deviceId;

    useEffect(() => {
        getDeviceSchema(setMasterDeviceSchema);
        getDevice(deviceId, setDeviceData);
    }, [deviceId]);

    const onSelectionChange = newItems => {
        const propertyObj = JSON.parse(JSON.stringify(masterDeviceSchema.properties.policy.properties));
        var newSchema = JSON.parse(JSON.stringify(masterDeviceSchema));
        var newProperties = {};
        newItems.forEach(item => newProperties[item] = propertyObj[item]);
        newSchema.properties.policy.properties = newProperties;
        setDeviceSchemaFunction(setDeviceSchema, JSON.parse(JSON.stringify(newSchema)));
        setDeviceData(updatedDeviceData);
    };

    if (masterDeviceSchema && !deviceSchema && deviceData && deviceData.policy) {
        var newSchema = JSON.parse(JSON.stringify(masterDeviceSchema));
        const keys = Object.keys(newSchema.properties.policy.properties);
        keys.forEach(key => {
            if (!Object.keys(deviceData.policy).includes(key)) {
                delete newSchema.properties.policy.properties[key];
            }
        })
        setDeviceSchema(newSchema);
    }

    if (deviceData && !updatedDeviceData) {
        updatedDeviceData = deviceData;
    }

    function showModal() {
        setVisible(true)
    };

    function handleCancel() {
        setVisible(false)
    };

    async function handleMenuClick(key) {
        switch (key) {
            case "1":
                if (deviceData.resource.applicationReports) {
                    navigate(routes.Device.InstalledApplicationList, { state: { installedApps: deviceData.resource.applicationReports } })
                }
                else {
                    message.error("ApplicationReports not available")
                }
                break;
            case "2":
                navigate(routes.Device.ApplicationsList, { state: { deviceId: deviceId } })
                break;
            case "3":
                showModal();
                break;
            case "4":
                setDeviceDeletionConfirmationModalVisibility(true)
                break;
            case "5":
                setVisibleDubugModeCode(true)
                break;
            case "6":
                await resetLockScreenPasswordForDevice(deviceId)
                break;
            case "7":
                const resp = await getPolicyToPreview(deviceId)
                if (resp) {
                    console.log("Policy Preview ::: ", resp.data)
                    alert("please view in logs : open Developer Tools and view in logs")
                } else {
                    alert("failed to fetch policy")
                }
                break;
            case "8":
                await patchPolicy(deviceId)
                break;
            case "9":
                let userId = prompt("Please enter User Id (to whom the device will transferred to...)");
                if (userId != null && userId.length > 0) {
                    const response = await transferDeviceToDifferentUser(deviceId, userId)
                    window.alert(response.message)
                } else {
                    window.alert("Please enter User Id")
                }
                break;
            case "10":
                await markDeviceAsLost(deviceId)
                break;
            default:
        }
    }

    async function sendPushNotificationMessage() {
        var requestBody = {}

        if (selectedPushNotification === 'INFO_MSG') {
            requestBody = { title: infoTitle, msg: infoMessage, appToOpen, urlToOpen }
        } else if (selectedPushNotification === 'CMD_KNOX_REMOVE_ACCOUNTS_BY_TYPE') {
            requestBody = { accountType }
        }
        console.log(requestBody);
        await sendPushNotification(deviceId, selectedPushNotification, requestBody);
    }

    function onDebugModeCodeModalClose() {
        setVisibleDubugModeCode(!visibleDubugModeCode)
    }

    return (
        <div>
            {deviceSchema && deviceData && masterDeviceSchema && <MultiSelectSchemaForm
                navigationKey="3"
                formTitle="Update Device"
                placeholder="Select Policy Properties"
                formSchema={deviceSchema}
                formData={deviceData}
                searchablePropertyKeys={masterDeviceSchema.properties.policy.properties}
                onFormChange={(val) => { if (JSON.stringify(val.formData) !== JSON.stringify(deviceData)) updatedDeviceData = val.formData; }}
                selectedItems={Object.keys(deviceSchema.properties.policy.properties)}
                selectionChange={onSelectionChange}
                actions={
                    <Dropdown overlay={menu} placement="bottomRight">
                        <Button type="primary">
                            Actions
                        </Button>
                    </Dropdown>
                }
                onSubmitForm={(updatedFormData, selectedItems) => onSubmitForm(deviceId, updatedFormData, selectedItems, navigate)}
            />}
            < Modal title="Delete Device" visible={deviceDeletionConfirmationModalVisibility} onOk={async () => { await deleteDevice(deviceId); setDeviceDeletionConfirmationModalVisibility(false); setDeviceDeletionSuccessModalVisibility(true); }} onCancel={() => setDeviceDeletionConfirmationModalVisibility(false)}>
                Are you sure you want to {deviceData && <div>
                    <h2>DELETE - {deviceData.deviceName}</h2>
                </div>}
            </Modal >
            < Modal title="Delete Device Success" visible={deviceDeletionSuccessModalVisibility} footer={[]} onCancel={() => { navigate(-1) }}>
                {deviceData && <div>
                    <h2>{deviceData.deviceName} deleted successfully</h2>
                    {deviceData.resource?.hardwareInfo?.serialNumber && <p>Serial Number: {deviceData.resource.hardwareInfo.serialNumber}</p>}
                    {deviceData.resource?.networkInfo?.imei && <p>IMEI: {deviceData.resource.networkInfo.imei}</p>}
                    {deviceData.controlSdk && <p>ControlSdk: {deviceData.controlSdk}</p>}
                    {deviceData.sdkDetails?.version && <p>SDK Version: {deviceData.sdkDetails.version}</p>}
                </div>}
            </Modal >
            <Modal
                visible={visible}
                title="Select Push Notification Message"
                onCancel={handleCancel}
                footer={[
                ]}
            >
                <Space size={20}>
                    <Select value={selectedPushNotification} style={{ width: '40vh' }} onChange={setSelectedPushNotification}>
                        {pushNotificationOptions.map(option => <Option value={option}>{option}</Option>)}
                    </Select>
                    <Button type="primary" onClick={() => { sendPushNotificationMessage(); setVisible(false); }}>Send</Button>
                </Space>
                {selectedPushNotification === 'INFO_MSG' && <div>
                    <Input allowClear placeholder="Enter Title" value={infoTitle} onChange={(e) => setInfoTitle(e.target.value)} style={{ width: '40vh', marginTop: '10px' }} />
                    <Input allowClear placeholder="Enter Message" value={infoMessage} onChange={(e) => setInfoMessage(e.target.value)} style={{ width: '40vh', marginTop: '10px' }} />
                    <Input allowClear placeholder="Enter App package name to open " value={appToOpen} onChange={(e) => setAppToOpen(e.target.value)} style={{ width: '40vh', marginTop: '10px' }} />
                    <Input allowClear placeholder="Enter URL to open " value={urlToOpen} onChange={(e) => setUrlToOpen(e.target.value)} style={{ width: '40vh', marginTop: '10px' }} />
                </div>}
                {selectedPushNotification === 'CMD_KNOX_REMOVE_ACCOUNTS_BY_TYPE' && <div>
                    <Input allowClear placeholder="Enter Account type" value={accountType} onChange={(e) => setAccountType(e.target.value)} style={{ width: '40vh', marginTop: '10px' }} />
                </div>}
            </Modal>
            <Modal
                visible={visibleDubugModeCode}
                centered={true}
                closable={false}
                onCancel={() => onDebugModeCodeModalClose()}
                onOk={() => onDebugModeCodeModalClose()}
                okText="close"
                cancelButtonProps={{ hidden: true }}
            >
                <Row justify="center">
                    <Row>
                        <Col span={24}>
                            <Title copyable={{ icon: [<CopyOutlined style={{ padding: '0 0px 0 40px' }} />, <CheckOutlined style={{ padding: '0 0px 0 40px' }} />] }}>{deviceData ? generateDebugModeCode(deviceData.deviceName) : ''}</Title>
                        </Col>
                    </Row>
                </Row >
            </Modal >
        </div >
    );
};

async function getDevice(deviceId, setDeviceData) {
    const response = await getDeviceFromId(deviceId);
    if (response) {
        var data = response.data;
        const datetimekeys = ['lastPolicySyncTime', 'lastStatusReportTime'];
        datetimekeys.forEach(key => {
            if (data.resource.hasOwnProperty(key) && data.resource[key]) {
                data.resource[key] = getRelativeTime(new Date(data.resource[key]));
            }
        })
        setDeviceData(data);
    }
}

async function getDeviceSchema(setMasterDeviceSchema) {
    setMasterDeviceSchema(EditDevicePageSchema.default);
}

function setDeviceSchemaFunction(setDeviceSchema, data) {
    setDeviceSchema(data);
}


async function onSubmitForm(deviceId, requestBody, selectedItems, navigate) {
    Object.keys(requestBody.policy).forEach(key => {
        if (!selectedItems.includes(key)) {
            delete requestBody.policy[key];
        }
    })
    const response = await updateDevice(deviceId, requestBody);
    navigate(routes.Device.List);
};




/**
 * steps :
 * 1. get device name
 * 2. count total of ascii value of every character of device name
 * 3. get UTC date time
 * 4. extract month, date, hour, minute
 * 5. count total of month, date, hour
 * 6. count total of ascii values of every character of count month+date+hour
 * 7. multiply by minute
 * 
 * THIS SHOULD BE SAME IN APP.
 */
function generateDebugModeCode(deviceName) {
    var deviceNameAsciiCharCodeAddition = 0
    for (var i = 0; i < deviceName.length; i++) {
        deviceNameAsciiCharCodeAddition += deviceName.charAt(i).charCodeAt()
    }

    /**
     * Time stamp in UTC format
     * we extract month, date, hour, minute
     */
    const timeStr = new Date()
        .toISOString()
        .replace('T', '-')
        .replace(':', '-')
        .split(':')
        .slice(0, -1)
        .join(':')
        .split(' ')
        .map((a, i) => i === 0 ? a.split('/').reverse().join('/') : a)
        .join(' ')
        .split('-')

    const month = timeStr[1]
    const date = timeStr[2]

    //First we will convert hour value from 12 hours format to 24 hours format
    const hour = (((parseInt(timeStr[3])) % 12 - 1) % 12 + 1).toString();

    const min = timeStr[4]

    const timeStringForCode = month + date + hour
    var timeAsciiCharCodeAddition = 0
    for (var i = 0; i < timeStringForCode.length; i++) {
        timeAsciiCharCodeAddition += timeStringForCode.charAt(i).charCodeAt()
    }

    return (timeAsciiCharCodeAddition * deviceNameAsciiCharCodeAddition * parseInt(min))
}