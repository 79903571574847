import React, { useEffect, useState } from "react";
import { Input } from 'antd';
import Navigation from "Components/Navigation";
import SchemaTable from 'Components/SchemaTable'
import { getAllGroups } from 'utils/apis';
import * as ListPageJson from './ListSchema.json';

export default function GroupsListPage() {
    const [groupsData, setGroupsData] = useState([]);
    const [searchvalue, setSearchValue] = useState('');

    useEffect(() => { getGroups() }, [])

    // useEffect(() => {
    //     setGroupsData({
    //         data: groupsData,
    //         // data: groupsData.filter(val => {
    //         //     if (searchvalue) {
    //         //         console.log({ searchvalue })
    //         //         if (val.name.toLowerCase().includes(searchvalue) || val.description.toLowerCase().includes(searchvalue)) {
    //         //             return true
    //         //         }
    //         //         return false
    //         //     } else {
    //         //         return true
    //         //     }
    //         // }),
    //         total: groupsData.length
    //     });
    // }, [searchvalue])

    async function getGroups() {
        var response = await getAllGroups(searchvalue);
        setGroupsData({
            data: response.data,
            total: response.data.length
        });
    }

    var pagination = { position: ['bottomLeft'], total: groupsData.total, onChange: getGroups }

    return (
        <div>
            <Navigation
                selectedKeys={['4']}
                content={
                    groupsData.length === 0 ? <div /> : < SchemaTable tableSchema={ListPageJson.default} tableData={groupsData.data} actionDataIndex="id" hasEditAction={false} options={{ pagination }} />
                }
                // addDataLeft={
                //     <Input placeholder="Search Group" value={searchvalue} onChange={(e) => setSearchValue(e.target.value)} style={{ width: '60vh' }} />
                // }
            />
        </div>
    );
}
