import { findByGroupName } from "utils/apis";
import SearchAndSelect from "./SearchAndSelect";
import { FIND_DEVICE_REQ_DEFAULT_LIMIT } from "../../constants";

const { useState } = require("react");

async function fetchGroups(groupName) {
    return findByGroupName(groupName, FIND_DEVICE_REQ_DEFAULT_LIMIT)
        .then((body) => {
            return body.data.map((group) => ({
                label: group.name,
                value: group.id,
            }))
        }
        );
}

export default function SearchAndSelectGroup({ setDistroTargetId }) {
    return <div>
        <SearchAndSelect
            placeholder="Seach Groups..."
            fetchOptions={fetchGroups}
            onChange={(newValue) => {
                setDistroTargetId(newValue)
            }}
            style={{
                width: '100%',
            }}
        />
    </div>
}
