import React, { useEffect, useState } from "react";
import { Button } from 'antd';
import { useNavigate } from "react-router-dom";
import Navigation from "Components/Navigation";
import PolicyListPageJson from './ListSchema.json';
import { getPolicyList } from 'utils/apis';
import { routes } from 'Routes/routes'
import { v4 as uuidv4 } from 'uuid';
import SchemaTable from "Components/SchemaTable";

export default function PolicyListPage() {
    let navigate = useNavigate();
    const [policyListData, setPolicyListData] = useState([]);
    const [policyData, setPolicyData] = useState([]);

    useEffect(() => { getPolicys() }, [])

    const onEdit = (item) => {
        console.log("Policy Item", item)
        navigate(routes.Policy.Edit, { state: { policyId: item } });
    };

    const navigateToCreatePolicy = () => {
        console.log(policyData)
        console.log(policyData.length !== 0 ? policyData[0].name : "")
        console.log(policyData.length !== 0 ? policyData[0].EntityId : "")
        navigate(
            routes.Policy.Create,
            {
                state: {
                    policyName: policyData.length !== 0 ? policyData[0].name : "",
                    entityId: policyData.length !== 0 ? policyData[0].EntityId : ""
                }
            }
        );
    }


    async function getPolicys(page, pageSize) {
        var response = await getPolicyList(page, pageSize);
        setPolicyListData({
            data: response.data,
            total: response.total
        });
        setPolicyData(
            response.data
        )
    }

    var pagination = { position: ['bottomLeft'], total: policyListData.total, onChange: getPolicys }

    return (
        <div>
            <Navigation
                selectedKeys={['1']}
                content={
                    policyListData.length === 0 ? <div /> : < SchemaTable tableSchema={PolicyListPageJson} tableData={policyListData.data} actionDataIndex="id" hasEditAction={true} onEdit={onEdit} options={{ pagination }} />
                }
                addData={
                    <Button type="primary" shape="round" onClick={navigateToCreatePolicy}>
                        Add Policy
                    </Button>
                }
            />
        </div>
    );
}


