import React from "react";
import { useNavigate } from "react-router-dom";
import { Form, Input, Button } from 'antd';
import 'Components/GlobalStyle.css';
import apis from 'utils/apis';
import { routes } from 'Routes/routes'

export default function LoginPage(props) {
    let navigate = useNavigate();
    async function loginMDM(requestBody) {
        try {
            var response = await apis.loginUser(requestBody);
            if (response) {
                navigate(routes.Entity.Selection);
            }
        }
        catch (e) {
            console.log(JSON.parse(e.message))
        }
    }

    return (
        <Form
            name="basic"
            labelCol={{ span: 9 }}
            wrapperCol={{ span: 6 }}
            initialValues={{ remember: true }}
            onFinish={loginMDM}
            style={{ marginTop: "50px" }}
            autoComplete="off"
        >
            <Form.Item
                label="Email"
                name="email"
                style={{ marginTop: "100" }}
                rules={[{ required: true, message: 'Please input your email address!' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Password"
                name="password"
                rules={[{ required: true, message: 'Please input your password!' }]}
            >
                <Input.Password />
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 9, span: 16 }}>
                <Button type="primary" htmlType="submit">
                    Submit
                </Button>
            </Form.Item>
        </Form>
    );
}
