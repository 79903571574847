import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import * as EditapplicationPageSchema from "./ApplicationEditSchema.json";
import {
  updateApplicationsFromDeviceId,
  getDeviceAppAssociation,
  updateApplicationsFromUserId,
} from "utils/apis";
import { routes } from "Routes/routes";
import MultiSelectSchemaForm from "Components/MultiSelectSchemaForm";
import { Button, Space } from "antd";

export default function EditApplicationPage(props) {
  const [applicationData, setApplicationData] = useState(null);
  const [applicationSchema, setApplicationSchema] = useState(null);
  const [masterApplicationSchema, setMasterApplicationSchema] = useState();

  // var updatedApplicationData = {};
  let navigate = useNavigate();
  let loc = useLocation();
  const applicationId = loc.state.applicationId;
  const userId = loc.state.userId;

  if (!applicationData) {
    // updatedApplicationData = { resource: loc.state.resource };
    setApplicationData({ resource: loc.state.resource });
  }

  if (applicationData) {
    console.log(applicationData);
  }
  useEffect(() => {
    getApplicationSchema(setMasterApplicationSchema);
  }, [applicationId, applicationData]);

  const onSelectionChange = (newItems) => {
    const propertyObj = JSON.parse(
      JSON.stringify(masterApplicationSchema.properties.resource.properties)
    );
    var newSchema = JSON.parse(JSON.stringify(masterApplicationSchema));
    var newProperties = {};
    newItems.forEach((item) => (newProperties[item] = propertyObj[item]));
    newSchema.properties.resource.properties = newProperties;
    setApplicationSchemaFunction(
      setApplicationSchema,
      JSON.parse(JSON.stringify(newSchema))
    );
    // setApplicationData(updatedApplicationData);
  };

  if (
    masterApplicationSchema &&
    !applicationSchema &&
    applicationData &&
    applicationData.resource
  ) {
    var newSchema = JSON.parse(JSON.stringify(masterApplicationSchema));
    const keys = Object.keys(newSchema.properties.resource.properties);
    keys.forEach((key) => {
      if (!Object.keys(applicationData.resource).includes(key)) {
        delete newSchema.properties.resource.properties[key];
      }
    });
    setApplicationSchema(newSchema);
  }

  return (
    <div>
      {applicationSchema && applicationData && masterApplicationSchema && (
        <MultiSelectSchemaForm
          navigationKey="2"
          formTitle="Update Application"
          placeholder="Select Application Properties"
          formSchema={applicationSchema}
          formData={applicationData}
          searchablePropertyKeys={
            masterApplicationSchema.properties.resource.properties
          }
          onFormChange={(val) => {
            if (
              JSON.stringify(val.formData) !== JSON.stringify(applicationData)
            ) {
              setApplicationData(val.formData);
            }
          }} //updatedApplicationData = val.formData; //setUpdatedApplicationData(val.formData); console.log(val.formData)   updatedApplicationData = val.formData;
          selectedItems={Object.keys(
            applicationSchema.properties.resource.properties
          )}
          selectionChange={onSelectionChange}
          actions={
            <Space size="middle">
              <Button
                type="primary"
                shape="round"
                onClick={() => {
                  const searchParams = new URLSearchParams();
                  searchParams.append("userId", userId);
                  navigate(
                    `${routes.User.ApplicationsList}?${searchParams.toString()}`
                  );
                }}
              >
                Applications
              </Button>
            </Space>
          }
          onSubmitForm={(updatedFormData, selectedItems) =>
            onSubmitForm(
              userId,
              applicationId,
              updatedFormData,
              selectedItems,
              navigate
            )
          }
        />
      )}
    </div>
  );
}

async function getApplicationSchema(setMasterApplicationSchema) {
  var applicationSchema = EditapplicationPageSchema.default;
  setMasterApplicationSchema(applicationSchema);
}

function setApplicationSchemaFunction(setApplicationSchema, data) {
  setApplicationSchema(data);
}

async function onSubmitForm(
  userId,
  applicationId,
  requestBody,
  selectedItems,
  navigate
) {
  Object.keys(requestBody.resource).forEach((key) => {
    if (!selectedItems.includes(key)) {
      delete requestBody.Device[key];
    }
  });
  const response = await updateApplicationsFromUserId(
    userId,
    applicationId,
    requestBody
  );
  console.log("Edit Application Response : ", response);
  const searchParams = new URLSearchParams();
  searchParams.append("userId", userId);
  navigate(`${routes.User.ApplicationsList}?${searchParams.toString()}`);
}
