import React from 'react';
import { Layout, Menu, Row, Col } from 'antd';
import { useNavigate } from "react-router-dom";
import { routes } from 'Routes/routes'

const { Header, Content } = Layout;
const menu = [
  { name: "Home", link: routes.Dashboard.View },
  { name: "Policies", link: routes.Policy.List },
  { name: "Users", link: routes.User.List },
  { name: "Devices", link: routes.Device.List },
  { name: "Groups", link: routes.Groups.List },
  { name: "Applications", link: routes.Application.List },
  { name: "Account", link: routes.Entity.Selection, style: { marginLeft: 'auto' } }
]

function Navigation(props) {
  let navigate = useNavigate();

  return (
    <Layout>
      <Header style={{ position: 'fixed', zIndex: 1, width: '100%', marginBottom: '100px' }}>
        <div className="logo" />
        <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['1']} selectedKeys={props.selectedKeys} onClick={(val) => { navigate(menu[val.key].link) }}>
          {menu.map((item, i) => {
            const style = item.style ? item.style : {}
            return <Menu.Item key={i} style={style}>{item.name}</Menu.Item>
          })}
        </Menu>
      </Header>
      <Content className="site-layout" style={{ padding: '0 50px', marginTop: 64 }}>
        <div className="site-layout-background" style={{ minHeight: "90vh", minWidth: "100vh", marginTop: 20 }}>
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '10px 0px'
          }}>
            {props.addDataLeft}
            <div />
            {props.addData}
          </div>
          {props.content}
        </div>
      </Content>
    </Layout >
  );
}

export default Navigation;