import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import * as EditapplicationPageSchema from './ApplicationEditSchema.json';
import { updateApplicationsFromPolicyId, getPolicyAppAssociation } from 'utils/apis';
import Navigation from 'Components/Navigation';
import { routes } from 'Routes/routes'
import { Button, Space } from 'antd';
import MultiSelectSchemaForm from 'Components/MultiSelectSchemaForm'

export default function EditApplicationPage(props) {
    const [applicationData, setApplicationData] = useState(null);
    const [applicationSchema, setApplicationSchema] = useState(null);
    const [masterApplicationSchema, setMasterApplicationSchema] = useState();

    var updatedApplicationyData = {};
    let navigate = useNavigate();
    let loc = useLocation();
    const applicationId = loc.state.applicationId;//"9e472f35-411e-487d-8832-74e4f80eb122";//loc.state.applicationId;
    const policyId = loc.state.policyId;

    if (!applicationData) {
        console.log(loc.state.resource)
        updatedApplicationyData = { resource: loc.state.resource };
        setApplicationData({ resource: loc.state.resource })
    }

    useEffect(() => {
        getApplicationSchema(setMasterApplicationSchema);
        // getApplication(policyId, applicationId, applicationData, setApplicationData, handleChange);
    }, [applicationId, applicationData]);

    const onSelectionChange = newItems => {
        const propertyObj = JSON.parse(JSON.stringify(masterApplicationSchema.properties.resource.properties));
        var newSchema = JSON.parse(JSON.stringify(masterApplicationSchema));
        var newProperties = {};
        newItems.forEach(item => newProperties[item] = propertyObj[item]);
        newSchema.properties.resource.properties = newProperties;
        setApplicationSchemaFunction(setApplicationSchema, JSON.parse(JSON.stringify(newSchema)));
        console.log(JSON.stringify(updatedApplicationyData, 2, 2))
        setApplicationData(updatedApplicationyData);
    };

    if (masterApplicationSchema && !applicationSchema && applicationData && applicationData.resource) {
        var newSchema = JSON.parse(JSON.stringify(masterApplicationSchema));
        const keys = Object.keys(newSchema.properties.resource.properties);
        keys.forEach(key => {
            if (!Object.keys(applicationData.resource).includes(key)) {
                delete newSchema.properties.resource.properties[key];
            }
        })
        setApplicationSchema(newSchema);
    }

    return (
        <div>
            {
                applicationSchema && applicationData && masterApplicationSchema && <MultiSelectSchemaForm
                    navigationKey="1"
                    formTitle="Update Application"
                    placeholder="Select Application Properties"
                    formSchema={applicationSchema}
                    formData={applicationData}
                    searchablePropertyKeys={masterApplicationSchema.properties.resource.properties}
                    onFormChange={(val) => { if (JSON.stringify(val.formData) !== JSON.stringify(applicationData)) updatedApplicationyData = val.formData; }}
                    selectedItems={Object.keys(applicationSchema.properties.resource.properties)}
                    selectionChange={onSelectionChange}
                    onSubmitForm={(updatedFormData, selectedItems) => onSubmitForm(policyId, applicationId, updatedFormData, selectedItems, navigate)}
                    actions={
                        <Space size="middle">
                            <Button type="primary" shape="round" onClick={() => { navigate(routes.Policy.ApplicationsList, { state: { policyId: policyId } }); }}>
                                Applications
                            </Button >
                        </Space>
                    }
                />
            }
        </div >
    );
};



async function getApplicationSchema(setMasterApplicationSchema) {
    var applicationSchema = EditapplicationPageSchema.default;
    setMasterApplicationSchema(applicationSchema);
}

function setApplicationSchemaFunction(setApplicationSchema, data) {
    setApplicationSchema(data);
}

async function onSubmitForm(policyId, applicationId, requestBody, selectedItems, navigate) {
    const newReq = {};
    Object.keys(requestBody.resource).forEach(key => {
        if (selectedItems.includes(key)) {
            newReq[key] = requestBody.resource[key];
        }
    })
    const response = await updateApplicationsFromPolicyId(policyId, applicationId, { resource: newReq });
    console.log("Edit Application Response : ", response);
    navigate(routes.Policy.ApplicationsList, { state: { policyId: policyId } });
};