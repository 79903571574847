import React from "react";
import { Button, Layout, Collapse, Row, Col, Tabs } from 'antd';
import { PlusCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import './FormStyle.css'
import Form from '@rjsf/antd';
const { Content } = Layout;
const { Panel } = Collapse;
const { TabPane } = Tabs;

export default function GenericForm(props) {
    return (
        < Layout >
            <Content className="site-layout">
                <div className="site-layout-background" style={{ marginBottom: 100, minHeight: 380 }}>
                    <h1>
                        {props.formTitle}
                    </h1>
                    {props.selectFormField && <div style={{ marginTop: 20, marginBottom: 20 }}>{props.selectFormField}</div>}
                    <Form
                        schema={props.schema}
                        formData={props.formData}
                        uiSchema={props.uiSchema}
                        ObjectFieldTemplate={ObjectFieldTemplate}
                        ArrayFieldTemplate={(props) => ArrayFieldTemplate(props)
                        }
                        onSubmit={(value) => props.onSubmitForm(value.formData)}
                        onChange={props.onFormChange ? props.onFormChange : () => { }}
                    >
                        {
                            props.getAdditionalComponent && props.getAdditionalComponent()
                        }
                        <Button type="primary" htmlType="submit" style={{ marginTop: 10, marginBottom: 30 }}>
                            Submit
                        </Button>
                    </Form >
                </div>
            </Content>
        </Layout >
    )
}

function ObjectFieldTemplate(props) {
    return (
        <div>
            {
                props.schema && props.schema.display === false ?
                    <div className="custom-remove-btn">{props.properties.map((element, index) => <div key={index}>{element.content}</div>)}
                        {props.schema.additionalProperties &&
                            (
                                <Button type="primary" icon={<PlusCircleOutlined />} onClick={props.onAddClick(props.schema)} >Add</Button>
                            )
                        }
                    </div>
                    :
                    <Collapse
                        defaultActiveKey={['1']}>
                        <Panel header={props.title} key={props.schema && props.schema.expanded === true ? "1" : "0"}>
                            <div className="custom-remove-btn">{props.properties.map((element, index) => <div key={index}>{element.content}</div>)}
                                {props.schema.additionalProperties &&
                                    (
                                        <Button type="primary" icon={<PlusCircleOutlined />} onClick={props.onAddClick(props.schema)} >Add</Button>
                                    )
                                }
                            </div>
                        </Panel>
                    </Collapse >
            }
        </div >
    );
}

function ArrayFieldTemplate(props) {
    return (
        <div className={props.className}>
            <p>
                {props.title}
            </p>
            {
                props.schema
                    && props.schema.tabView === true ?
                    <Row justify="end">
                        <Col span={21}>
                            {props.items && <Tabs defaultActiveKey="1">
                                {
                                    props.items.map(element => {
                                        const copy = { ...element };
                                        var children = { ...copy.children }
                                        children.props.schema.display = false;
                                        Object.preventExtensions(children);
                                        return (
                                            <TabPane tab={`Tab ${element.index + 1}`} key={(element.index + 1)}>
                                                <div key={element.key} className={element.className}>
                                                    {children}
                                                    <Button type="primary" danger icon={<DeleteOutlined />} style={{ marginBottom: '30px' }} onClick={element.onDropIndexClick(element.index)}>
                                                        Remove
                                                    </Button>
                                                </div>
                                            </TabPane>
                                        );
                                    })
                                }
                            </Tabs>
                            }
                        </Col>
                        <Col span={3}>
                            {
                                props.canAdd && (
                                    <Button type="primary" icon={<PlusCircleOutlined />} onClick={props.onAddClick} >
                                        Add
                                    </Button>
                                )
                            }
                        </Col>
                    </Row>
                    :
                    <div>
                        {
                            props.items && props.items.map(element => {
                                return (
                                    <div key={element.key} className={element.className}>
                                        <Row justify="end">
                                            <Col flex='40px'>
                                                <Button type="primary" shape="circle" >{element.index + 1}</Button>
                                            </Col>
                                            <Col flex="auto">
                                                <div>{element.children}</div>
                                            </Col>
                                            <Col flex='40px'>
                                                <Button type="primary" danger style={{ float: 'right' }} icon={<DeleteOutlined />} onClick={element.onDropIndexClick(element.index)} />
                                            </Col>
                                        </Row>
                                    </div>
                                )
                            })
                        }
                        {
                            props.canAdd && (
                                <Button type="primary" icon={<PlusCircleOutlined />} onClick={props.onAddClick} >
                                    Add
                                </Button>
                            )
                        }
                    </div>
            }
        </div >

    );
}

