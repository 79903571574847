import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import Form from 'Components/SchemaForm'
import { Transfer, Typography, Card } from 'antd';
import * as EditAdminPageSchema from './EditSchema.json';
import * as EntitiesDataJson from './EntitiesData.json'
import apis from 'utils/apis';
import Navigation from "Components/SANavigation";
import { routes } from 'Routes/routes'
const { Title } = Typography;

export default function EditAdminPage(props) {
    const [adminData, setAdminData] = useState(null);
    const [adminSchema, setAdminSchema] = useState({});
    let navigate = useNavigate();
    let loc = useLocation();
    const adminId = loc.state.adminId;

    useEffect(() => {
        getAdmin(adminId, adminData, setAdminData);
        getAdminSchema(setAdminSchema);
    }, [adminId, adminData]);


    const mockData = [];

    EntitiesDataJson.default.data.forEach(val => {
        mockData.push({
            key: val.id,
            title: val.name,
            description: `description of content ${val.name}`,
        });
    });

    const [targetKeys, setTargetKeys] = useState([]);
    const [selectedKeys, setSelectedKeys] = useState([]);

    if (targetKeys.length === 0 && adminData && adminData.EntityAdmins.length !== 0) setTargetKeys(adminData.EntityAdmins.map(val => val.EntityId))
    const onChange = (nextTargetKeys, direction, moveKeys) => {
        console.log('targetKeys:', nextTargetKeys);
        setTargetKeys(nextTargetKeys);
    };

    const onSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
        setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
    };

    const onScroll = (direction, e) => {
        console.log('direction:', direction);
        console.log('target:', e.target);
    };
    const AdditionalComponent = <div style={{ padding: '10px 15px 0 15px', backgroundColor: 'white' }}>
        {/* <Title level={4}>Edit Entity</Title> */}
        <Card title="Edit Entities" size="small">
            <Transfer
                dataSource={mockData}
                titles={['Source', 'Selected Entities']}
                targetKeys={targetKeys}
                selectedKeys={selectedKeys}
                onChange={onChange}
                onSelectChange={onSelectChange}
                onScroll={onScroll}
                render={item => item.title}
                listStyle={{
                    width: 'calc(50%)',
                    height: 'calc(50%)',
                    backgroundColor: 'white'
                }}
                style={{ padding: '0 0 20px 0' }}
            />
        </Card>

    </div>;

    return (
        <div>
            <Navigation
                selectedKeys={['1']}
                content={
                    adminData && adminSchema ?
                        <div>
                            <Form formTitle="Update Admin" formData={adminData} schema={adminSchema} getAdditionalComponent={() => AdditionalComponent} onSubmitForm={(value) => onSubmitForm(adminId, value, targetKeys, navigate)} />
                        </div>
                        :
                        <div />
                }
            />
        </div>
    );
};


async function getAdmin(adminId, adminData, setAdminData) {
    const response = await apis.getAdminFromId(adminId);
    console.log(response);
    if (response) {
        if (!adminData) setAdminData(response.data);
    }
}

async function getAdminSchema(setAdminSchema) {
    setAdminSchema(EditAdminPageSchema.default);
}

async function onSubmitForm(adminId, requestBody, selectedEntityIds, navigate) {
    console.log("admin form submit : ", requestBody)
    console.log("admin entities : ", EntitiesDataJson.default.data.filter(val => selectedEntityIds.includes(val.id)))
    const response = "";//await apis.updateAdmin(adminId, requestBody);
    console.log("Update admin ---->>>", response)
    // navigate(routes.Admin.List);
};
