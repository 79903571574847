import React, { useEffect, useState } from "react";
import { Input } from 'antd';
import Navigation from "Components/Navigation";
import * as ApplicationsListSchemaJson from './ListSchema.json';
import SchemaTable from 'Components/SchemaTable'
import { getAllAMApplicationList } from 'utils/apis';
import { routes } from 'Routes/routes'
import { useNavigate } from "react-router-dom";
import AddNewAppComponent from "./components/AddNewAppComponent";

var currPage = 1;
var currPageSize = 100; //Setting up default value

export default function ApplicationsListPage() {
    let navigate = useNavigate();
    const [amApplications, setAMApplications] = useState({});
    const [amApplicationListSchema, setAMApplicationListSchema] = useState([]);
    const [searchvalue, setSearchValue] = useState('');

    useEffect(() => {
        getAmApplicationsSchema(ApplicationsListSchemaJson, setAMApplicationListSchema);
        getAmApplications(currPage, currPageSize);
    }, [searchvalue]);

    async function getAmApplicationsSchema(ApplicationsListSchemaJson, setAMApplicationListSchema) {
        setAMApplicationListSchema(ApplicationsListSchemaJson.default);
    }

    async function getAmApplications(page, pageSize) {
        var response = await getAllAMApplicationList(searchvalue);
        currPage = page;
        currPageSize = pageSize;

        setAMApplications({
            data: response.data,
            total: response.total
        });
    }

    const openAppDetails = (item) => { navigate(routes.Application.View, { state: { appId: item.id } }); };

    const pageSizeOptions = [10, 50, 100, 500]
    var pagination = { position: ['bottomLeft'], total: amApplications.total, onChange: getAmApplications, defaultPageSize: currPageSize, pageSizeOptions, showSizeChanger: true }
    return (
        <div>
            <Navigation
                selectedKeys={['5']}
                content={
                    amApplications.length === 0 || amApplicationListSchema.length === 0 ?
                        <div /> :
                        <SchemaTable
                            tableSchema={amApplicationListSchema}
                            tableData={amApplications.data}
                            actionDataIndex="id" options={{ pagination }}
                            onRowClick={openAppDetails}
                        />
                }
                addData={<AddNewAppComponent />}
                addDataLeft={
                    <div style={{ width: '100%' }}>
                        <Input placeholder="Search Name" value={searchvalue} onChange={(e) => setSearchValue(e.target.value)} style={{ width: '60vh', marginTop: '10px' }} />
                    </div>
                }
            />
        </div >
    );
}