
import LoadingSpinner from "Components/LoadingSpinner";
import _ from "lodash";
import { useState } from "react";
import { useEffect } from "react";
import { getAppInstallations } from "utils/apis";
const { Space, List, Descriptions, Button, Modal, Collapse, Tabs } = require("antd");
const { Panel } = Collapse;

export default function AppInstallations({ appId }) {

    const [devicesData, setDevicesData] = useState(null)

    async function fetchAppInstallationSummary() {
        const response = await getAppInstallations(appId)
        if (response) {
            setDevicesData(response.data)
        } else {
            console.log('Looks like an Error has occoured!')
        }
    }

    useEffect(async () => {
        fetchAppInstallationSummary()
    }, [])

    if (devicesData == null) {
        return <>
            <LoadingSpinner loadingText={"Loading summary..."} />
        </>
    } else {
        return <>
            <Space direction="vertical">
                <InstallationSummaryComponent />
            </Space>
        </>
    }

    function InstallationSummaryComponent() {

        const [showDevicesPerVersionModal, setShowDevicesPerVersionModal] = useState(false)

        const devicesPerVersions = _.chain(devicesData)
            .groupBy('version')
            .map((value, key) => ({ version: key, devices: value }))
            .sortBy('version')
            .reverse()
            .value()

        const allDevicesItem =
            <Descriptions.Item
                span={8}
                label={'Total Installations'}>
                {devicesData.length} device(s)
                <Button type="ghost" onClick={() => { setShowDevicesPerVersionModal(true) }}>
                    View all devices
                </Button>
            </Descriptions.Item>
        const versionElementListItems = []
        versionElementListItems.push(allDevicesItem)

        function getTabListItemElement(devices, includeVersion = false) {
            return <List dataSource={devices} renderItem={(item) =>
                <List.Item>
                    <List.Item.Meta
                        title={item.name + ' - ' + item.user}
                        description={includeVersion ? 'v' + item.version : <></>}
                    />
                </List.Item>}
            />
        }
        const allDevicesTab = { label: 'All', key: 'all', children: (<>{getTabListItemElement(devicesData, true)}</>) }
        const versionWiseTabElements = []
        versionWiseTabElements.push(allDevicesTab)

        devicesPerVersions.forEach((item) => {
            versionElementListItems.push(<Descriptions.Item label={'v' + item.version}>{item.devices.length}</Descriptions.Item>)
            versionWiseTabElements.push({
                label: 'v' + item.version,
                key: item.version,
                children: (<>{getTabListItemElement(item.devices)}</>)
            })
        })

        return <>
            <Descriptions column={{
                xxl: 8,
                xl: 7,
                lg: 5,
                md: 3,
                sm: 2,
                xs: 1,
            }} bordered>
                {versionElementListItems}
            </Descriptions>
            <Modal
                title={"Devices"}
                width={'50%'}
                open={showDevicesPerVersionModal}
                closable
                footer={null}
                onCancel={() => setShowDevicesPerVersionModal(false)}
                okText={null}
            >
                <Tabs items={versionWiseTabElements} />
            </Modal>
        </>
    }
}

