
/**
 * https://stackoverflow.com/a/53800501
 * 
 * @returns time string relatively to now
 */
const timeUnits = {
    year: 24 * 60 * 60 * 1000 * 365,
    month: 24 * 60 * 60 * 1000 * 365 / 12,
    day: 24 * 60 * 60 * 1000,
    hour: 60 * 60 * 1000,
    minute: 60 * 1000,
    second: 1000
}
export function getRelativeTime(d1, d2 = new Date()) {
    try {
        const rtf = new Intl.RelativeTimeFormat('en', { numeric: 'auto' })
        var elapsed = d1 - d2
        // "Math.abs" accounts for both "past" & "future" scenarios
        for (var u in timeUnits)
            if (Math.abs(elapsed) > timeUnits[u] || u == 'second')
                return rtf.format(Math.round(elapsed / timeUnits[u]), u)
    } catch (error) {
    }
    return d1.toString()
}