import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Form from 'Components/SchemaForm'
import * as CreateAdminPageSchema from './CreateSchema.json';
import apis from 'utils/apis';
import Navigation from "Components/SANavigation";
import { routes } from 'Routes/routes'


export default function CreateAdminPage(props) {
    let navigate = useNavigate();
    const [createAdminSchema, setCreateAdminSchema] = useState({});
    useEffect(() => {
        getCreateAdminSchema(setCreateAdminSchema);
    }, []);

    return (
        <div>
            <Navigation
                selectedKeys={['1']}
                content={
                    createAdminSchema ? <Form formTitle="Create Admin" schema={createAdminSchema} onSubmitForm={(value) => onSubmitForm(value, navigate)} /> : <div />
                }
            />
        </div>
    );
};

async function onSubmitForm(requestBody, navigate) {
    console.log(requestBody);
    //const response = await apis.createAdmin(requestBody);
    navigate(routes.Admin.List);
};

async function getCreateAdminSchema(setAdminSchema) {
    console.log(CreateAdminPageSchema.default);
    setAdminSchema(CreateAdminPageSchema.default);
}