import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import Form from 'Components/SchemaForm'
import * as CreateDeviceRequestPageSchema from './CreateSchema.json';
import { getPolicyList, createDeviceRequest } from 'utils/apis';
import Navigation from "Components/Navigation";
import { routes } from 'Routes/routes'
import { Typography, Button, Modal, Row, Col } from 'antd';
import { CloseCircleOutlined, CopyOutlined, CheckOutlined } from '@ant-design/icons';

const { Title } = Typography;
const DummyResponse = {
    "code": 201,
    "message": "data has been added!",
    "data": {
        "id": "a6e679a2-6af8-40ca-8619-64cc65449590",
        "UserId": "15de21bd-b574-4025-bc07-3741a3d78270",
        "enrollmentDetails": "PERSONAL_USAGE_DISALLOWED",
        "AMGroupPolicyId": "b9adf0a7-311c-4e66-8d14-e881a6a6d18e",
        "deviceName": "Samsung A3122",
        "osPlatform": "ANDROID",
        "passcode": "49885077",
        "updatedAt": "2022-02-09T06:36:12.151Z",
        "createdAt": "2022-02-09T06:36:12.151Z",
        "deletedAt": null
    }
}

export default function CreateDeviceRequestPage(props) {
    let navigate = useNavigate();
    let loc = useLocation();
    const userId = loc.state.UserId;
    const formData = {
        UserId: userId,
        enrollmentDetails: "PERSONAL_USAGE_DISALLOWED"
    };
    const [createDeviceRequestSchema, setCreateDeviceRequestSchema] = useState(null);
    const [visible, setVisible] = useState(false);
    const [passcode, setPassCode] = useState();

    useEffect(() => {
        getCreateDeviceRequestSchema(setCreateDeviceRequestSchema);
    });

    return (
        <div>
            <Navigation
                selectedKeys={['2']}
                content={
                    createDeviceRequestSchema ? <Form getAdditionalComponent={showModal} formTitle="Create DeviceRequest" formData={formData} schema={createDeviceRequestSchema} onSubmitForm={onSubmitForm} /> : <div />
                }
            />
        </div >
    );


    function showModal() {
        return <Modal
            visible={visible}
            centered={true}
            closable={false}
            onCancel={() => onModalClose()}
            onOk={() => onModalClose()}
            okText="close"
            cancelButtonProps={{ hidden: true }}
        >
            <Row justify="center">
                <Row>
                    <Col span={24}>
                        <Title copyable={{ icon: [<CopyOutlined style={{ padding: '0 0px 0 40px' }} />, <CheckOutlined style={{ padding: '0 0px 0 40px' }} />] }}>{passcode}</Title>
                    </Col>
                </Row>
            </Row >
        </Modal >
    }

    function onModalClose() {
        setVisible(!visible)
        navigate(routes.User.Edit, { state: { userId: userId } });
    }

    async function onSubmitForm(requestBody) {
        const response = await createDeviceRequest(requestBody);
        console.log(JSON.stringify(response, null, 2))
        if (response && response.code && response.code === 201) {
            setPassCode(response.data.passcode)
            setVisible(true)
        }
        // setVisible(true)
        // console.log("response : ", response);

    };
};

async function getCreateDeviceRequestSchema(setDeviceRequestSchema) {
    var schema = CreateDeviceRequestPageSchema.default;
    const response = await getPolicyList()
    console.log(response)
    if (response && response.data) {
        schema.properties.AMGroupPolicyId.enum = response.data.map(val => val.id);
        schema.properties.AMGroupPolicyId.enumNames = response.data.map(val => val.name);
    }
    console.log(schema)
    setDeviceRequestSchema(schema);
}