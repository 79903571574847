import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import Form from 'Components/SchemaForm'
import * as EditDeviceRequestPageSchema from './EditSchema.json';
import { getDeviceRequestFromId, updateDeviceRequest } from 'utils/apis';
import Navigation from 'Components/Navigation';
import { routes } from 'Routes/routes'
import { Button } from 'antd'

export default function EditDeviceRequestPage(props) {
    const [deviceRequestData, setDeviceRequestData] = useState(null);
    const [deviceRequestSchema, setDeviceRequestSchema] = useState({});
    let navigate = useNavigate();
    let loc = useLocation();
    const deviceRequestId = "";//loc.state.deviceRequestId;

    useEffect(() => {
        getDeviceRequestSchema(setDeviceRequestSchema);
        getDeviceRequest(deviceRequestId, deviceRequestData, setDeviceRequestData);
    }, [deviceRequestId, deviceRequestData]);
    //navigate(routes.Admin.Create)
    return (
        <div>
            <Navigation
                selectedKeys={['2']}
                content={
                    deviceRequestData && deviceRequestSchema ? <Form formTitle="Update DeviceRequest" formData={deviceRequestData} schema={deviceRequestSchema} onSubmitForm={(value) => onSubmitForm(deviceRequestId, value, navigate)} /> : <div />
                }
                addData={
                    <Button type="primary" shape="round" onClick={() => { console.log("add Device"); }}>
                        Add Device
                    </Button>
                }
            />
        </div >
    );
};

async function getDeviceRequest(deviceRequestId, deviceRequestData, setDeviceRequestData) {
    const response = await getDeviceRequestFromId(deviceRequestId);
    if (response) {
        if (!deviceRequestData) setDeviceRequestData(response.data);
    }
}

async function getDeviceRequestSchema(setDeviceRequestSchema) {
    setDeviceRequestSchema(EditDeviceRequestPageSchema.default);
}

async function onSubmitForm(deviceRequestId, requestBody, navigate) {
    console.log(requestBody)
    const response = await updateDeviceRequest(deviceRequestId, requestBody);
    navigate(routes.DeviceRequest.List);
};