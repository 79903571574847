import { findByDeviceName } from "utils/apis";
import SearchAndSelect from "./SearchAndSelect";

const { useState } = require("react");

const FIND_DEVICE_REQ_DEFAULT_LIMIT = 10
async function fetchDevices(deviceName) {
    return findByDeviceName(deviceName, FIND_DEVICE_REQ_DEFAULT_LIMIT)
        .then((body) => {
            return body.data.map((device) => ({
                label: device.deviceName,
                value: device.id,
            }))
        }
        );
}

export default function SearchAndSelectDevice({ setDistroTargetId }) {
    return <div>
        <SearchAndSelect
            placeholder="Seach Devices..."
            fetchOptions={fetchDevices}
            onChange={(newValue) => {
                setDistroTargetId(newValue)
            }}
            style={{
                width: '100%',
            }}
        />
    </div>
}
