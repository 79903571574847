import { Spin } from "antd";

export default function LoadingSpinner({ loadingText='Loading...' }) {
    return <Spin
        style={{
            padding: '80px', display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }}
        tip={loadingText}
        size="large" />
}