import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import * as EditPolicyPageSchema from './EditSchema.json';
import { updatePolicy, getPolicyFromId, sendPolicyGroupPushNotification } from 'utils/apis';
import { routes } from 'Routes/routes'
import MultiSelectSchemaForm from 'Components/MultiSelectSchemaForm'
import { Button, Space, Menu, Dropdown, Select, Modal, Input, message } from 'antd';
const { Option } = Select;

export default function EditPolicyPage(props) {
    const pushNotificationOptions = [
        'CMD_SYNC_CONTROL_SDK_POLICY',
        'CMD_ACTIVATE_KNOX_LICENSE',
        'CMD_SYNC_FCM_TOKEN',
        'CMD_SYNC_AM_POLICY',
        'INFO_DEVICE_NAME_UPDATED',
        'INFO_MSG',
        'CMD_SET_ONLY_ALLOWED_GMAIL_ACCOUNTS_IN_PLAY_STORE',
    ]
    const menu = (
        <Menu onClick={(e) => handleMenuClick(e.key)} style={{ width: '200px' }}>
            <Menu.Item key="2" >
                Edit Applications
            </Menu.Item>
            <Menu.Item key="3" >
                Send Push Notification
            </Menu.Item>
        </Menu >
    );

    const [policyData, setPolicyData] = useState(null);
    const [policySchema, setPolicySchema] = useState(null);
    const [masterPolicySchema, setMasterPolicySchema] = useState();

    const [visible, setVisible] = useState(false);
    const [selectedPushNotification, setSelectedPushNotification] = useState(pushNotificationOptions[0]);
    const [infoTitle, setInfoTitle] = useState('');
    const [infoMessage, setInfoMessage] = useState('');
    const [appToOpen, setAppToOpen] = useState('');
    const [urlToOpen, setUrlToOpen] = useState('');

    var updatedPolicyData = null;
    let navigate = useNavigate();
    let loc = useLocation();
    const policyId = loc.state.policyId;

    useEffect(() => {
        getPolicySchema(setMasterPolicySchema);
        getPolicy(policyId, setPolicyData);
    }, [policyId]);


    const onSelectionChange = newItems => {
        const propertyObj = JSON.parse(JSON.stringify(masterPolicySchema.properties.policy.properties));
        var newSchema = JSON.parse(JSON.stringify(masterPolicySchema));
        var newProperties = {};
        newItems.forEach(item => newProperties[item] = propertyObj[item]);
        newSchema.properties.policy.properties = newProperties;
        setPolicySchemaFunction(setPolicySchema, JSON.parse(JSON.stringify(newSchema)));
        setPolicyData(updatedPolicyData);
    };

    if (masterPolicySchema && !policySchema && policyData && policyData.policy) {
        var newSchema = JSON.parse(JSON.stringify(masterPolicySchema));
        const keys = Object.keys(newSchema.properties.policy.properties);
        keys.forEach(key => {
            if (!Object.keys(policyData.policy).includes(key)) {
                delete newSchema.properties.policy.properties[key];
            }
        })
        setPolicySchema(newSchema);
    }

    if (policyData && !updatedPolicyData) {
        updatedPolicyData = policyData;
    }

    function showModal() {
        setVisible(true)
    };

    function handleCancel() {
        setVisible(false)
    };

    async function handleMenuClick(key) {
        switch (key) {
            case "2":
                navigate(routes.Policy.ApplicationsList, { state: { policyId: policyId } })
                break;
            case "3":
                showModal();
                break;
            default:
        }
    }

    async function sendPushNotificationMessage() {
        const requestBody = selectedPushNotification === 'INFO_MSG' ? { title: infoTitle, msg: infoMessage, appToOpen, urlToOpen } : {};
        console.log(requestBody);
        await sendPolicyGroupPushNotification(policyId, selectedPushNotification, requestBody);
    }

    return (
        <div>
            {policySchema && policyData && masterPolicySchema && <MultiSelectSchemaForm
                navigationKey="1"
                formTitle="Update Policy"
                placeholder="Select Policy Properties"
                formSchema={policySchema}
                formData={policyData}
                searchablePropertyKeys={masterPolicySchema.properties.policy.properties}
                onFormChange={(val) => { if (JSON.stringify(val.formData) !== JSON.stringify(policyData)) updatedPolicyData = val.formData; }}
                selectedItems={Object.keys(policySchema.properties.policy.properties)}
                selectionChange={onSelectionChange}
                onSubmitForm={(updatedFormData, selectedItems) => onSubmitForm(policyId, updatedFormData, selectedItems, navigate)}
                actions={
                    <Dropdown overlay={menu} placement="bottomRight" >
                        <Button type="primary">
                            Actions
                        </Button>
                    </Dropdown>
                }
            />}
            <Modal
                visible={visible}
                title="Select Push Notification Message"
                onCancel={handleCancel}
                footer={[
                ]}
            >
                <Space size={20}>
                    <Select value={selectedPushNotification} style={{ width: '40vh' }} onChange={setSelectedPushNotification}>
                        {pushNotificationOptions.map(option => <Option value={option}>{option}</Option>)}
                    </Select>
                    <Button type="primary" onClick={() => { sendPushNotificationMessage(); setVisible(false); }}>Send</Button>
                </Space>
                {selectedPushNotification === 'INFO_MSG' && <div>
                    <Input allowClear placeholder="Enter Title" value={infoTitle} onChange={(e) => setInfoTitle(e.target.value)} style={{ width: '40vh', marginTop: '10px' }} />
                    <Input allowClear placeholder="Enter Message" value={infoMessage} onChange={(e) => setInfoMessage(e.target.value)} style={{ width: '40vh', marginTop: '10px' }} />
                    <Input allowClear placeholder="Enter App package name to open " value={appToOpen} onChange={(e) => setAppToOpen(e.target.value)} style={{ width: '40vh', marginTop: '10px' }} />
                    <Input allowClear placeholder="Enter URL to open" value={urlToOpen} onChange={(e) => setUrlToOpen(e.target.value)} style={{ width: '40vh', marginTop: '10px' }} />
                </div>}
            </Modal>
        </div >
    );
};

async function getPolicy(policyId, setPolicyData) {
    var response = await getPolicyFromId(policyId);
    if (response) {
        setPolicyData(response.data);
    }
}

async function getPolicySchema(setMasterPolicySchema) {
    var policySchema = EditPolicyPageSchema.default;
    delete policySchema.properties.policy.properties.applications;
    setMasterPolicySchema(policySchema);
}

function setPolicySchemaFunction(setPolicySchema, data) {
    setPolicySchema(data);
}

async function onSubmitForm(policyId, requestBody, selectedItems, navigate) {

    Object.keys(requestBody.policy).forEach(key => {
        if (!selectedItems.includes(key)) {
            delete requestBody.policy[key];
        }
    })

    const response = await updatePolicy(policyId, requestBody);
    navigate(routes.Policy.List);
};