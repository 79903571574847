module.exports = Object.freeze({
    FIND_ELEMENT_REQ_DEFAULT_LIMIT: 10,
    // baseUrl: 'http://192.168.105.82:8510',
    // baseUrl: 'https://api.mdm.sarangapps.ga',
    baseUrl: 'https://api-pramukhmdm.esatsang.net',
    // baseUrl: 'https://api-dev.pramukhmdm.esatsang.net',
    //'https://api.mdm.sarangapps.ga',
    //http:/192.168.82.8510 
});


