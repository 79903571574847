import React, { useEffect, useState } from "react";
import { Button, Select, message, Space, Input } from 'antd';
import { useNavigate, useLocation } from "react-router-dom";
import Navigation from "Components/Navigation";
import ApplicationsListPageJson from './ApplicationsListSchema.json';
import SchemaTable from 'Components/SchemaTable'
import { getApplicationsFromPolicyId, getAMApplicationList, createPolicyAppAssociation, deletePolicyAppAssociation, getAll, getAllAMApplicationList } from 'utils/apis';
import { routes } from 'Routes/routes'
import { PlusCircleOutlined } from '@ant-design/icons';

export default function ApplicationsList() {
    let navigate = useNavigate();
    const [applicationListData, setApplicationListData] = useState({});
    const [amAppsData, setAMAppsData] = useState([]);
    const [selectedApp, setSelectedApp] = useState();
    const [selectedInstallType, setSelctedInstallType] = useState("AVAILABLE");
    const [searchValue, setSearchValue] = useState('');

    let loc = useLocation();
    const policyId = loc.state.policyId;

    useEffect(() => {
        getPolicies(policyId, setApplicationListData);
        getAmApplications(setAMAppsData);
    }, [policyId]);

    const onEdit = (app) => {
        navigate(routes.Policy.ApplicationEdit, { state: { policyId: policyId, applicationId: app.id, resource: app.resource } });
    };

    async function onDelete(app) {
        const res = await deletePolicyAppAssociation(policyId, app.id)
        var data = [...JSON.parse(JSON.stringify(applicationListData.data.filter((val) => val.id !== app.id)))];
        if (res && res.code === 200) {
            setApplicationListData({ data, total: data.length });
        }
        else {
            message.error('Unable to delete entity - application association');
        }
    };

    const updateApplicationList = async (newApp) => {
        newApp.actionObject = { ...newApp }
        setApplicationListDataFunction
            ([
                ...JSON.parse(JSON.stringify(applicationListData.data)),
                newApp
            ])
    };

    const associateSelectedApp = async () => {
        if (selectedApp) {
            const newApp = JSON.parse(selectedApp);
            const res = await createPolicyAppAssociation({
                "AMGroupPolicyId": policyId,
                "AMAppId": newApp.id,
                "resource": {
                    "installType": selectedInstallType
                }
            });
            if (res && res.code === 201) {
                setSelectedApp(null);
                updateApplicationList({ ...newApp, ...res.data })
            }
            else {
                message.error('Unable to associate application with policy');
            }
        }
        else {
            message.error('Please Select Application');
        }
    }

    const applicationListDataIds = applicationListData.data && applicationListData.data.map(val => val.id)
    const filteredMenuItems = amAppsData.filter(o => applicationListData && !applicationListDataIds.includes(o.id))

    const menu = filteredMenuItems.map(item => (
        <Select.Option key={item.title} value={JSON.stringify(item)}>
            {item.title}
        </Select.Option>
    ))

    const installOptions = [
        "INSTALL_TYPE_UNSPECIFIED",
        "PREINSTALLED",
        "FORCE_INSTALLED",
        "BLOCKED",
        "AVAILABLE",
        "REQUIRED_FOR_SETUP",
        "KIOSK"
    ].map((item, index) => (
        <Select.Option key={index} value={item}>
            {item}
        </Select.Option>
    ))

    async function getPolicies(policyId) {
        var response = await getApplicationsFromPolicyId(policyId);
        const data = response.data.map((val) => {
            return (
                {
                    "id": val.id,
                    "title": val.title,
                    "packageName": val.packageName,
                    "resource": val.resource,
                    "actionObject": val
                }
            );
        });
        setApplicationListData({
            data,
            total: response.total
        });
    }

    async function getAmApplications() {
        var response = await getAll(getAMApplicationList);
        setAMAppsData(
            response.data
        );
    }

    function setApplicationListDataFunction(data) {
        setApplicationListData({
            data: JSON.parse(JSON.stringify(data)),
            total: data.length
        });
    }

    const filteredApplicationListData = applicationListData.data && applicationListData.data.length > 0 ?
        applicationListData.data.filter(app => {
            console.log((app.title.toLowerCase().includes(searchValue.toLowerCase()) || app.packageName.toLowerCase().includes(searchValue.toLowerCase())))
            return (app.title.toLowerCase().includes(searchValue.toLowerCase()) || app.packageName.toLowerCase().includes(searchValue.toLowerCase()))
        })
        :
        [];

    console.log(applicationListData)
    return (
        <div>
            <Navigation
                selectedKeys={['1']}
                content={
                    filteredApplicationListData.length === 0 || ApplicationsListPageJson.length === 0 ? <div /> : < SchemaTable tableSchema={ApplicationsListPageJson} tableData={filteredApplicationListData} actionDataIndex="actionObject" hasEditAction={true} onEdit={onEdit} hasDeleteAction={true} onDelete={(val) => onDelete(val)} />
                }
                addDataLeft={
                    <div style={{ width: '100%' }}>
                        <Space >
                            <Input placeholder="Search Name" value={searchValue} onChange={(e) => setSearchValue(e.target.value)} style={{ width: '60vh' }} />
                            <Select
                                showSearch
                                optionFilterProp="children"
                                placeholder="Select Application"
                                value={selectedApp}
                                onChange={setSelectedApp}
                                style={{ width: '250px' }}
                            >
                                {
                                    menu
                                }
                            </Select>
                            <Select
                                // showSearch
                                optionFilterProp="children"
                                placeholder="Select InstallType"
                                style={{ width: '250px' }}
                                value={selectedInstallType}
                                onChange={setSelctedInstallType}
                            >
                                {
                                    installOptions
                                }
                            </Select>
                            <Button type="primary" shape="round" icon={<PlusCircleOutlined />} onClick={associateSelectedApp} >Associate Application</Button>
                        </Space>
                    </div>
                }
            />
        </div >
    );
}
