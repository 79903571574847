import { findByUserName } from "utils/apis";
import SearchAndSelect from "./SearchAndSelect";
import { FIND_DEVICE_REQ_DEFAULT_LIMIT } from "../../constants";

const { useState } = require("react");

async function fetchUsers(name) {
    return findByUserName(name, FIND_DEVICE_REQ_DEFAULT_LIMIT)
        .then((body) => {
            return body.data.map((user) => ({
                label: user.username + " - " + user.fullname,
                value: user.id,
            }))
        }
        );
}

export default function SearchAndSelectUser({ setDistroTargetId }) {
    return <div>
        <SearchAndSelect
            // value={value}
            placeholder="Seach Users..."
            fetchOptions={fetchUsers}
            onChange={(newValue) => {
                setDistroTargetId(newValue)
            }}
            style={{
                width: '100%',
            }}
        />
    </div>
}
