import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import * as CreatePolicyPageSchema from './CreateSchema.json';
import { createPolicy } from 'utils/apis';
import { routes } from 'Routes/routes'
import { Button, Space } from 'antd';
import MultiSelectSchemaForm from 'Components/MultiSelectSchemaForm'

export default function CreatePolicyPage(props) {
    var updatedPolicyData = {};
    let navigate = useNavigate();
    let loc = useLocation();
    const { policyId, policyName, entityId } = loc.state;

    const initialPolicyData = {
        name: policyName,
        EntityId: entityId
    };

    const initialSchema = {
        "type": "object",
        "expanded": true,
        "properties": {
            "name": {
                "type": "string"
            },
            "EntityId": {
                "type": "string"
            },
            "policy": {
                "title": "AMGroup Policy Properties",
                "type": "object",
                "expanded": true,
                "properties": {}
            }
        }
    };

    const [policyData, setPolicyData] = useState(initialPolicyData);
    const [createPolicySchema, setCreatePolicySchema] = useState(initialSchema);
    const [masterPolicySchema, setMasterPolicySchema] = useState();

    useEffect(() => {
        getCreatePolicySchema(setMasterPolicySchema);
    });

    const onSelectionChange = newItems => {
        const propertyObj = JSON.parse(JSON.stringify(masterPolicySchema.properties.policy.properties));
        var newSchema = JSON.parse(JSON.stringify(masterPolicySchema));
        var newProperties = {};
        newItems.forEach(item => newProperties[item] = propertyObj[item]);
        newSchema.properties.policy.properties = newProperties;
        setPolicySchemaFunction(setCreatePolicySchema, JSON.parse(JSON.stringify(newSchema)));
        setPolicyData(updatedPolicyData);
    };

    return (
        <div>
            {createPolicySchema && policyData && masterPolicySchema && <MultiSelectSchemaForm
                navigationKey="1"
                formTitle="Create Policy"
                placeholder="Select Policy Properties"
                formSchema={createPolicySchema}
                formData={policyData}
                searchablePropertyKeys={masterPolicySchema.properties.policy.properties}
                onFormChange={(val) => { if (JSON.stringify(val.formData) !== JSON.stringify(policyData)) updatedPolicyData = val.formData; }}
                selectedItems={Object.keys(createPolicySchema.properties.policy.properties)}
                selectionChange={onSelectionChange}
                onSubmitForm={(updatedFormData, selectedItems) => onSubmitForm(updatedFormData, selectedItems, navigate)}
                actions={
                    <Space size="middle">
                        <Button type="primary" shape="round" onClick={() => { navigate(routes.Policy.ApplicationsList, { state: { policyId: policyId } }) }}>
                            Applications
                        </Button >
                    </Space>
                }
            />}
        </div >
    );

};

function setPolicySchemaFunction(setPolicySchema, data) {
    setPolicySchema(data);
}

async function getCreatePolicySchema(setPolicySchema) {
    setPolicySchema(CreatePolicyPageSchema.default);
}

async function onSubmitForm(requestBody, selectedItems, navigate) {
    Object.keys(requestBody.policy).forEach(key => {
        if (!selectedItems.includes(key)) {
            delete requestBody.policy[key];
        }
    })

    const response = await createPolicy(requestBody);
    console.log(response);
    navigate(routes.Policy.List);
};