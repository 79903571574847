import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import Form from 'Components/SchemaForm'
import { Select, Button } from 'antd';
import * as EditPolicyPageSchema from './EditSchema.json';
import { updatePolicy, getPolicyFromId } from 'utils/apis';
import Navigation from 'Components/Navigation';
import { routes } from 'Routes/routes'

const intialSchema = {
    "type": "object",
    "expanded": true,
    "properties": {
        "policy": {
            "title": "Policy Properties",
            "type": "object",
            "expanded": true,
            "properties": {}
        }
    }
}

export default function EditPolicyPage(props) {
    const [policyData, setPolicyData] = useState(null);
    const [policySchema, setPolicySchema] = useState(JSON.parse(JSON.stringify(intialSchema)));
    const [masterPolicySchema, setMasterPolicySchema] = useState();
    const [selectedItems, setSelectedItems] = useState([]);

    let navigate = useNavigate();
    let loc = useLocation();
    const policyId = loc.state.policyId;
    const policyType = loc.state.policyType;

    useEffect(() => {
        getPolicySchema(setMasterPolicySchema);
        getPolicy(policyId, policyData, setPolicyData, loc.state);
    }, [policyId, policyData]);

    const handleChange = newItems => {
        const propertyObj = JSON.parse(JSON.stringify(masterPolicySchema.properties.policy.properties));
        var newSchema = JSON.parse(JSON.stringify(masterPolicySchema));
        var newProperties = {};
        newItems.forEach(item => newProperties[item] = propertyObj[item]);
        newSchema.properties.policy.properties = newProperties;
        setPolicySchemaFunction(setPolicySchema, JSON.parse(JSON.stringify(newSchema)));
        setSelectedItems(newItems);
    };

    if (masterPolicySchema && policyData && selectedItems.length === 0) {
        if (policyData.policy && Object.keys(policyData.policy).length !== 0) {
            handleChange(Object.keys(policyData.policy));
        }
    }
    const filteredMenuItems = (masterPolicySchema) ?
        Object.keys(masterPolicySchema.properties.policy.properties).filter(o => !selectedItems.includes(o))
        :
        []

    return (
        <div>
            <Navigation
                selectedKeys={[policyType === "DevicePolicy" ? '3' : '2']}
                content={
                    < div >
                        {
                            policyData ? <Form formTitle="Update Policy" formData={policyData} schema={policySchema} onSubmitForm={(value) => onSubmitForm(policyId, value, selectedItems, navigate)}
                                selectFormField={
                                    <Select
                                        mode="multiple"
                                        placeholder="Select AMGroup Policy Properties"
                                        value={selectedItems}
                                        onChange={(val) => handleChange(val)}
                                        style={{ width: '100%' }
                                        }
                                    >
                                        {
                                            filteredMenuItems.map(item => (
                                                <Select.Option key={item} value={item}>
                                                    {item}
                                                </Select.Option>
                                            ))
                                        }
                                    </Select>
                                }
                            /> :
                                < div />
                        }
                    </div >
                }
                addData={
                    policyData ?
                        <Button type="primary" shape="round" onClick={() => { navigate(routes.Policy.ApplicationsList, { state: { policyId: policyId, policyType: policyType } }); }}>
                            Edit Applications
                        </Button > : <div />
                }
            />
        </div >
    );
};

async function getPolicy(policyId, policyData, setPolicyData, state) {
    console.log(state)
    if (!policyData) {
        if (state.policyType === "DevicePolicy") {
            setPolicyData({ policy: state.policy });
        }
        else {
            const response = await getPolicyFromId(policyId);
            console.log(response.data)
            if (response) {
                setPolicyData(response.data);
            }
        }
    }
}

async function getPolicySchema(setMasterPolicySchema) {
    var policySchema = EditPolicyPageSchema.default;
    delete policySchema.properties.policy.properties.applications;
    setMasterPolicySchema(policySchema);
}

function setPolicySchemaFunction(setPolicySchema, data) {
    setPolicySchema(data);
}

async function onSubmitForm(policyId, requestBody, selectedItems, navigate) {
    console.log(requestBody)
    // console.log(Object.keys(requestBody.policy))
    const newReq = {};
    Object.keys(requestBody.policy).forEach(key => {
        if (selectedItems.includes(key)) {
            newReq[key] = requestBody.policy[key];
        }
    })
    console.log(newReq)
    const response = await updatePolicy(policyId, requestBody);
    console.log(response)
    //navigate(routes.Policy.List);
};