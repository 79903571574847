import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import Form from 'Components/SchemaForm'
import * as CreateEntityPageSchema from './CreateSchema.json';
import apis from 'utils/apis';
import Navigation from "Components/Navigation";
import { routes } from 'Routes/routes'

export default function CreateEntityPage(props) {
    let navigate = useNavigate();
    const [createEntitySchema, setCreateEntitySchema] = useState({});
    const EntityId = localStorage.getItem('EntityId');

    useEffect(() => {
        getCreateEntitySchema(setCreateEntitySchema);
    });

    return (
        <div>
            <Navigation
                selectedKeys={['2']}
                content={
                    createEntitySchema ? <Form formTitle="Create User" formData={{ EntityId: EntityId }} schema={createEntitySchema} onSubmitForm={(value) => onSubmitForm(value, navigate)} /> : <div />
                }
            />
        </div >
    );
};

async function onSubmitForm(requestBody, navigate) {
    const response = await apis.createUser(requestBody);
    console.log("response : ", response);
    navigate(routes.User.List);
};

async function getCreateEntitySchema(setEntitySchema) {
    console.log(CreateEntityPageSchema.default);
    setEntitySchema(CreateEntityPageSchema.default);
}