export const routes = {
  Login: {
    Login: "/",
  },
  Dashboard: {
    View: "/dashboard",
  },
  Entity: {
    List: "/entities",
    Create: "/entity/create",
    Edit: "/entity/edit",
    Selection: "/entity/select",
  },
  Admin: {
    List: "/admins",
    Create: "/admin/create",
    Edit: "/admin/edit",
  },
  User: {
    List: "/users",
    Create: "/user/create",
    Edit: "/user/edit",
    ApplicationsList: "/user/applications",
    ApplicationEdit: "/userapplication/edit",
  },
  Device: {
    List: "/devices",
    Create: "/device/create",
    Edit: "/device/edit",
    ApplicationsList: "/device/applications",
    ApplicationEdit: "/deviceapplication/edit",
    InstalledApplicationList: "/device/installedapps",
  },
  DeviceRequest: {
    List: "/devicerequests",
    Create: "/devicerequest/create",
    Edit: "/devicerequest/edit",
  },
  Policy: {
    List: "/policies",
    Create: "/policy/create",
    Edit: "/policy/edit",
    ApplicationsList: "/policy/applications",
    ApplicationEdit: "/policyapplication/edit",
  },
  Groups: {
    List: "/groups",
  },
  Application: {
    List: "/applications",
    View: "/applications/view",
  },
};

function getPaths(key, value) {
  if (typeof value == "string") {
    return { [key]: value };
  } else if (typeof value == "object") {
    var data = {};
    Object.keys(value).forEach((val) => {
      const path = key ? `${key}/${val}` : val;
      data = { ...data, ...getPaths(path, value[val]) };
    });
    return data;
  }
}

function getPages(paths) {
  let pages = {};
  Object.keys(paths).forEach((route, i) => {
    pages[paths[route]] = require(`Pages/${route}`).default;
  });
  return pages;
}

export const pages = getPages(getPaths("", routes));

//"ApplicationEdit": "/deviceapplication/edit"
