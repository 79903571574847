const { Card, Descriptions, List } = require("antd");

export default function AppBasicDetails({ appDetails }) {
    return <div>
        <Descriptions>
            <Descriptions.Item label="App title">{appDetails.title}</Descriptions.Item>
            <Descriptions.Item label="Package Name">{appDetails.packageName}</Descriptions.Item>
            <Descriptions.Item label="Distribution channel">{appDetails.resource.distributionChannel}</Descriptions.Item>
            <Descriptions.Item label="App type">{appDetails.appType}</Descriptions.Item>
            <Descriptions.Item label="Category">{appDetails.resource.category ?? '-'}</Descriptions.Item>
            <Descriptions.Item label="App tracks">
                {(appDetails.resource.appTracks == null)
                    ? "Only Production track"
                    : <List
                        itemLayout="vertical"
                        dataSource={appDetails.resource.appTracks}
                        renderItem={(item) =>
                            <div> {item.trackAlias}</div>
                        }
                    />
                }
            </Descriptions.Item>
            <Descriptions.Item label="Description">{appDetails.resource.description}</Descriptions.Item>
        </Descriptions>
    </div>
}