import React, { useState } from 'react';
import Form from 'Components/SchemaForm'
import { Select, Button, Space } from 'antd';
import Navigation from 'Components/Navigation';


export default function MultiSelectSchemaForm(props) {
    const filteredMenuItems = (props.formSchema) ?
        Object.keys(props.searchablePropertyKeys).filter(o => !props.selectedItems.includes(o))
        :
        []

    return (
        <div>
            <Navigation
                selectedKeys={[props.navigationKey]}
                content={
                    < div >
                        {
                            <Form
                                formTitle={props.formTitle}
                                formData={props.formData}
                                schema={props.formSchema}
                                onSubmitForm={(updatedFormData) => props.onSubmitForm(updatedFormData, props.selectedItems)}
                                onFormChange={props.onFormChange}
                                selectFormField={
                                    <Select
                                        mode="multiple"
                                        placeholder={props.placeholder}//"Select AMGroup Policy Properties"
                                        value={props.selectedItems}
                                        onChange={(val) => { props.selectionChange(val) }}
                                        style={{ width: '100%' }
                                        }
                                    >
                                        {
                                            filteredMenuItems.map(item => (
                                                <Select.Option key={item} value={item}>
                                                    {item}
                                                </Select.Option>
                                            ))
                                        }
                                    </Select>
                                }
                            />
                        }
                    </div >
                }
                addData={props.actions}
            />
        </div >
    );
};