import React, { useEffect, useState } from "react";
import { Button, Layout, Row, Col } from 'antd';
import { useNavigate } from "react-router-dom";
import * as EntitySelectionPageJson from './Selection.json';
import GenericTable from 'Components/GenericTable'
import apis from 'utils/apis';
import { routes } from 'Routes/routes'

const { Content } = Layout;


export default function EntitySelectionPage() {
    let navigate = useNavigate();
    const [entityListData, setEntityListData] = useState([]);
    useEffect(() => getEntityList(setEntityListData), [])

    const onEdit = async (item) => {
        localStorage.setItem('EntityId', item.id);
        await apis.setEntity(item.id);
        navigate(routes.Dashboard.View);
    };

    return (
        <Layout>
            <Content className="site-layout" style={{ padding: '0 50px' }}>
                <div className="site-layout-background" style={{ marginTop: 50, minHeight: 380 }}>
                    <Row>
                        <Col flex={12} />
                        <Col flex={0.2} style={{ marginBottom: 10 }} >
                            <Button type="primary" shape="round" onClick={() => { navigate(routes.Entity.List) }}>
                                Manage Entities
                            </Button>
                        </Col>
                    </Row>
                    {entityListData.length === 0 ? <div /> : < GenericTable tableFields={EntitySelectionPageJson} tableData={entityListData} onRowClick={onEdit} />}
                </div>
            </Content>
        </Layout>
    );
}

async function getEntityList(setEntityListData) {
    var response = await apis.getEntitySelectionList();
    setEntityListData(
        response.data.map((val, i) => {
            var data = {};
            EntitySelectionPageJson.default.forEach((field) => {
                data[field.dataIndex] = val[field.dataField]
            })
            console.log(i, "data", data)
            return data;
        })
    );
}