import React from 'react';

import {
    Table, Space, Button
} from 'antd';

export default function GenericTable(props) {
    var columns = JSON.parse(JSON.stringify(props.tableFields)).default.filter((val) => !val.hidden).map((field) => {
        return {
            title: field.name,
            dataIndex: field.dataIndex,
        }
    });

    if (props.hasEditAction || props.hasDeleteAction) {
        columns.push({
            title: "Action",
            dataIndex: "id",
            render: (item) => {
                return <div>
                    {props.hasEditAction ? <Button type='link' onClick={() => props.onEdit(item)} > Edit</Button > : <div />}
                    {props.hasDeleteAction ? <Button danger type='link' onClick={() => props.onDelete(item)} > Delete</Button > : <div />}
                </div>
            }
        })
    }

    return (
        < Table dataSource={props.tableData}
            columns={columns}
            rowKey="id"
            onRow={(record, rowIndex) => {
                if (props.onRowClick) {
                    return {
                        onClick: () => { props.onRowClick(props.tableData[rowIndex]) },
                    };
                }
            }}
        />
    );
};
