import React, { useEffect, useState } from "react";
import {Input, Button } from "antd";
import { useNavigate } from "react-router-dom";
import Navigation from "Components/Navigation";
import UserListPageJson from "./ListSchema.json";
import { getAllUserList } from "utils/apis";
import { routes } from "Routes/routes";
import SchemaTable from "Components/SchemaTable";

export default function UserListPage() {
  let navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [searchvalue, setSearchValue] = useState("");

  useEffect(() => {
    getUsers();
  }, [searchvalue]);

  const onEdit = (item) => {
    navigate(routes.User.Edit, { state: { userId: item } });
  };

  async function getUsers(page, pageSize) {
    var response = await getAllUserList(searchvalue);
    setUsers({
      data: response.data,
      total: response.total,
    });
  }

  var pagination = {
    position: ["bottomLeft"],
    total: users.total,
    onChange: getUsers,
  };

  return (
    <div>
      <Navigation
        selectedKeys={["2"]}
        content={
          users.length === 0 ? (
            <div />
          ) : (
            <SchemaTable
              tableSchema={UserListPageJson}
              tableData={users.data}
              actionDataIndex="id"
              hasEditAction={true}
              onEdit={onEdit}
              options={{ pagination }}
            />
          )
        }
        addDataLeft={
          <Input
            placeholder="Search Name"
            value={searchvalue}
            onChange={(e) => setSearchValue(e.target.value)}
            style={{ width: "60vh" }}
          />
        }
        addData={
          <Button
            type="primary"
            shape="round"
            onClick={() => {
              navigate(routes.User.Create);
            }}
          >
            Add User
          </Button>
        }
      />
    </div>
  );
}
