import React, { useEffect, useState } from "react";
import { Button } from 'antd';
import { useNavigate } from "react-router-dom";
import Navigation from "Components/SANavigation";
import EntityListPageJson from './ListSchema.json';
import SchemaTable from 'Components/SchemaTable'
import apis from 'utils/apis';
import { routes } from 'Routes/routes'
import { replaceData } from "draft-js/lib/DraftEntity";

export default function EntityListPage() {
    let navigate = useNavigate();
    const [entityListData, setEntityListData] = useState([]);
    useEffect(() => getEntityList(setEntityListData), [])

    const onEdit = (item) => {
        console.log("Entity OnEdit ----------", item)
        navigate(routes.Entity.Edit, { state: { entityId: item } });
    };

    async function getEntityList(page, pageSize) {
        var response = await apis.getEntityList(page, pageSize);
        setEntityListData({
            data: response.data,
            total: response.total
        });
    }

    var pagination = { position: ['bottomLeft'], total: entityListData.total, onChange: getEntityList, }

    return (
        <div>
            <Navigation
                selectedKeys={['0']}
                content={
                    entityListData.length === 0 ? <div /> : < SchemaTable tableSchema={EntityListPageJson} tableData={entityListData.data} actionDataIndex="id" hasEditAction={true} onEdit={onEdit} options={{ pagination }} />
                }
                addData={
                    <Button type="primary" shape="round" onClick={() => { console.log("add Entity"); navigate(routes.Entity.Create) }}>
                        Add Entity
                    </Button>
                }
            />
        </div>
    );
}

